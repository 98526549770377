<template>
  <!-- <LazyHydrate when-visible> -->
  <div :class="{ 'has-edit-button': isPreview }">
    <br-manage-content-button :content="content" />
    <div class="mj-product-carousel" :class="color === 'grey' ? 'mj-product-carousel__background-grey' : ''">
      <div class="container">
        <header class="mj-product-carousel__header" v-if="title">
          <h2>{{ title }}</h2>
        </header>
        <ProductsCarousel
          :products="products"
          :loading="productsLoading"
          :data-cache-id="cacheId"
          :centerInsufficientSlides="true"
          :add-to-cart-buttons="showAddToCartButtons"
        />
        <div v-if="buttonLabel" class="mj-product-carousel__button">
          <a
            v-if="externalLink"
            class="sf-button sf-button--hollow action primary link--external"
            :href="externalLink"
            :aria-label="buttonLabel"
          >
            {{ buttonLabel }}
          </a>
          <nuxt-link
            v-else
            :to="localePath(internalLink)"
            class="sf-button sf-button--hollow action primary"
            :title="buttonLabel"
            :aria-label="buttonLabel"
          >
            {{ buttonLabel }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
  <!-- </LazyHydrate> -->
</template>

<script lang="ts" setup>
import type { Page, Component } from '@bloomreach/spa-sdk'
import productGetters from '~/utils/getters/magentoProductGetters'
import { useProduct } from '~/composables'
import type { Ref } from '@nuxtjs/composition-api'

interface Props {
  component: Component
  page: Page
}

const props = defineProps<Props>()

const { $encodeBase64 } = useNuxtApp()

const { document } = props.component?.getModels()
const content = ref(props.page.getContent(document))
const pageData = ref(content.value?.getData())

const isPreview = ref(props.page.isPreview())
const color = ref(pageData.value?.color?.selectionValues[0]?.key)
const title = ref(pageData.value?.title)
const buttonLabel = ref(pageData.value?.buttonLabel)
const externalLink = ref(pageData.value?.ctaUrl)
const internalLink = ref(props.page?.getContent(pageData.value?.ctaLink)?.getUrl())

const category = ref($encodeBase64(pageData?.value?.categoryManual))
const sortValue = ref(pageData?.value?.categorySort)
const skus = ref(pageData?.value?.productsManual)
const productFilters = ref(pageData.value?.productFilters?.map((item) => props.page.getContent(item).getData() || {}))

const cacheId = `products=${skus.value?.join(',') || ''}&category=${category.value || ''}`

const { getProductList } = useProduct(cacheId)
const localePath = useLocalePath()

const productsLoading = ref(false)
const products = ref([])

const doSearch = async () => {
  // Check if anything is selected, otherwise bail.
  if (!skus.value?.length && !category.value?.length && !productFilters.value?.length) {
    return
  }

  productsLoading.value = true

  const searchQuery = {
    pageSize: category.value?.length ? 10 : 50,
    currentPage: 0,
    customQuery: {
      products: 'products',
      metadata: {},
    },
    filter: {
      ...getFilterStatement(skus, category, productFilters),
    },
    sort: {
      ...getSortStatement(sortValue?.value?.selectionValues[0]?.key, skus?.value?.[0] !== ''),
    },
  }

  const productsResult = await getProductList(searchQuery)

  products.value = productGetters
    .getFiltered(productsResult?.items, { master: true })
    .sort((a, b) => skus.value?.indexOf(a?.sku) - skus.value?.indexOf(b?.sku))

  productsLoading.value = false
}

const showAddToCartButtons = <Ref<boolean>>ref(pageData.value?.addToCartButtons)

onMounted(doSearch)

const getFilterStatement = (skus, category, productFilters) => {
  if (productFilters.value) {
    return createCustomFilter(productFilters)
  } else if (skus?.value?.[0] !== '') {
    return {
      sku: {
        in: skus?.value,
      },
    }
  } else {
    return {
      category_uid: {
        eq: category?.value,
      },
    }
  }
}

const createCustomFilter = (productFilters) => {
  const result = {}

  productFilters.value.map((filter) => {
    const key = filter.filterType.selectionValues[0].key
    let value = filter[key] ? filter[key].selectionValues[0].key : filter['value']
    if (key === 'category_uid') {
      value = $encodeBase64(value)
    }

    result[key] = {
      in: [value],
    }
  })

  return result
}

const getSortStatement = (sortValue, isSkuSearch) => {
  if (!isSkuSearch && sortValue && sortValue === 'priceAscending') {
    return { price: 'ASC' }
  } else if (!isSkuSearch && sortValue && sortValue === 'priceDescending') {
    return { price: 'DESC' }
  } else {
    return {}
  }
}
</script>

<style lang="scss">
@include for-mobile {
  .hst-container-item:has(.mj-product-carousel) {
    grid-column: 1 / -1;
  }
}

.mj-product-carousel {
  --carousel-width: 100%;
  padding-bottom: var(--spacer-xl);
  padding-top: var(--spacer-2xs);
  margin-bottom: var(--mj-component-margin-bottom);

  h2 {
    padding: 0 var(--spacer-sm);
    margin: var(--spacer-xs) 0;
  }

  @include for-mobile {
    padding-top: var(--spacer-xl);
  }

  .sf-section {
    --section-margin: 0;
    --section-content-margin: 0;

    @include for-desktop {
      --section-content-margin: var(--spacer-base);
    }

    @include for-mobile {
      .sf-heading {
        padding-bottom: 0;
      }

      .sf-heading__title {
        margin-bottom: 0;
      }
    }
  }

  .sf-carousel__controls {
    z-index: 1;
    pointer-events: none;

    .sf-button {
      pointer-events: initial;
    }
  }

  .sf-carousel-item {
    height: auto; /* Fixes flex stretch */
  }

  &__button {
    width: fit-content;
    margin: var(--spacer-lg) auto 0;
  }

  &__background-grey {
    background-color: var(--gray-background-color);

    .products-carousel {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
</style>
