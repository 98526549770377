<template>
  <div
    v-if="component && showRow"
    class="row"
    :class="{ 'has-edit-button': isPreview, 'add-padding': backgroundColor }"
    :style="backgroundColor ? `background-color: ${backgroundColor}` : ''"
    :data-columns="columns.length"
    :data-align="verticalAlign"
  >
    <br-manage-content-button :content="document" />
    <div v-for="(column, index) in columns" :key="index" class="column" :class="getColumnClass(index)">
      <component :is="getContentType(column)" :document="getDocument(column)" :page="page" :component="component" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Accordion from '~/components/Bloomreach/Accordion.vue'
import RichText from '~/components/Bloomreach/RichText.vue'

const mapping = {
  mjRichText: RichText,
  mjFaq: Accordion,
}

const props = defineProps({
  component: null,
  page: null,
})

const { isMobile } = useDevice()

const isPreview = props.page?.isPreview() || false

const showRow = computed(() => {
  return (
    (!isMobile && !props.component?.getParameters()?.mobileOnly) ||
    (isMobile && !props.component?.getParameters()?.desktopOnly)
  )
})

const document = computed(() => {
  const { document: ref } = props.component?.getModels()
  return props.page.getContent(ref)
})

const getDocument = (ref) => {
  return props.page?.getContent(ref)
}

const getColumnClass = (index) => {
  return [
    `w-${props.component?.getParameters()?.[`width${index + 1}`]?.toLowerCase() || 'auto'}`,
    `mobile-order-${props.component?.getParameters()?.[`sortOrderMobile${index + 1}`]?.toLowerCase() || '1'}`,
  ]
}

const getContentType = (column) => {
  const model = props.page?.getContent(column)?.model
  return mapping[model?.data?.contentType.replace('brxsaas:', '')] || undefined
}

const columns = computed(() => {
  return Object.values(
    Object.entries(props.component?.getModels() || {})
      .filter(([k, v]) => v !== null)
      .sort(([k1], [k2]) => k1.localeCompare(k2))
      .map((v) => v[1]),
  )
})

const backgroundColor = props.component?.getParameters()?.backgroundColor
const verticalAlign = props.component?.getParameters()?.verticalAlign?.toLowerCase()
</script>

<style lang="scss">
.add-padding {
  @include for-mobile {
    padding: var(--spacer-base) 0;
  }
  .rich-text {
    margin-top: var(--mj-component-margin-bottom) !important;
  }
}

.row {
  --column-gap: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--spacer-base);
  margin-bottom: var(--mj-component-margin-bottom);

  &[data-align='top'] {
    align-items: flex-start;
  }

  &[data-align='middle'] {
    align-items: center;
  }

  &[data-align='bottom'] {
    align-items: flex-end;
  }

  @include for-desktop {
    flex-direction: row;
    gap: 20px;

    &[data-columns='2'] {
      --column-spacer-horizontal: var(--spacer-2xl);

      @media screen and (max-width: 1179px) {
        --column-spacer-horizontal: var(--spacer-xl);
      }

      .column {
        &.w-auto,
        &.w-6 {
          > .rich-text {
            padding-left: var(--column-spacer-horizontal);
            padding-right: var(--column-spacer-horizontal);
          }

          .bynder-asset {
            left: 50%;
            transform: translate(-50%, 0);
            width: calc(100% + var(--column-spacer-horizontal) * 2);
          }
        }
      }
    }
  }

  > .column {
    flex: 0 0 100%;
    max-width: 100vw;

    --mj-richtext-padding-top: 0;
    --mj-component-margin-bottom: 0;

    @include for-mobile {
      &.mobile-order-1 {
        order: 1;
      }

      &.mobile-order-2 {
        order: 2;
      }

      &.mobile-order-3 {
        order: 3;
      }

      &.mobile-order-4 {
        order: 4;
      }

      &.mobile-order-5 {
        order: 5;
      }
    }

    @include for-desktop {
      flex: 1;
      --mj-richtext-padding-top: 0;
      --mj-component-margin-bottom: var(--spacer-xl);

      &.w-1 {
        flex-basis: calc(1 / 12 * 100% - var(--column-gap));
      }

      &.w-2 {
        flex-basis: calc(2 / 12 * 100% - var(--column-gap));
      }

      &.w-3 {
        flex-basis: calc(25% - var(--column-gap));
      }

      &.w-4 {
        flex-basis: calc(33.33% - var(--column-gap));
      }

      &.w-5 {
        flex-basis: calc(5 / 12 * 100% - var(--column-gap));
      }

      &.w-6 {
        flex-basis: calc(50% - var(--column-gap));
      }

      &.w-7 {
        flex-basis: calc(7 / 12 * 100% - var(--column-gap));
      }

      &.w-8 {
        flex-basis: calc(66.66% - var(--column-gap));
      }

      &.w-9 {
        flex-basis: calc(75% - var(--column-gap));
      }

      &.w-10 {
        flex-basis: calc(10 / 12 * 100% - var(--column-gap));
      }

      &.w-11 {
        flex-basis: calc(11 / 12 * 100% - var(--column-gap));
      }

      &.w-12 {
        flex-basis: calc(100% - var(--column-gap));
      }
    }
  }

  .sf-heading {
    --heading-text-align: left;
    --heading-padding: 0;

    .sf-heading__title.h2 {
      --heading-title-font-family: var(--font-family--primary);
      --heading-title-font-size: var(--h4-font-size);
      --heading-title-font-weight: var(--font-weight--semibold);
      --heading-title-margin: 0 0 var(--spacer-xs) var(--spacer-sm) !important;

      @include for-desktop {
        --heading-title-margin: 0 0 var(--spacer-xs) !important;
      }
    }
  }
}
</style>
