<template>
  <div class="hero-article-wrapper">
    <SfBanner
      v-if="banner"
      :title="banner.bannerTitle"
      :subtitle="banner.category"
      :description="banner.bannerDescription"
      :button-text="banner.ctaTitle"
      :button-link="banner.ctaUrl"
      class="hero-article"
    >
      <template #description>
        <p class="sf-banner__description" v-dompurify-html="banner.bannerDescription" />
      </template>
      <template #call-to-action>
        <BynderAsset :data="banner.asset" size="100vw" />
        <SfLink
          class="hero-article__button sf-button"
          :aria-disabled="false"
          :link="localePath(banner.ctaUrl || banner.ctaLink)"
        >
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 13.0001L7 7.00012L1 1.00012"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </SfLink>
      </template>
    </SfBanner>
  </div>
</template>

<script setup lang="ts">
interface Props {
  banners: any[]
}

const props = defineProps<Props>()
const banner = computed(() => props.banners[0] || false)
const localePath = useLocalePath()
</script>

<style lang="scss">
.hero-article {
  position: relative;
  overflow: hidden;
  --banner-align-items: flex-end;
  --banner-color: var(--white-color);
  --banner-wrapper-width: 100%;
  --button-background: var(--primary-color);
  --button-color: var(--black-color);
  --button-text-transform: none;
  --banner-width: auto;
  --banner-height: 536px;
  --banner-subtitle-text-transform: none;
  --banner-subtitle-font: var(--font-family--secondary);
  --font-size--base: 14px;
  --h2-font-size: 40px;
  --banner-title-margin: 9px 0 0;
  --banner-description-margin: 9px 40px 0 0;
  --banner-title-text-transform: none;
  --banner-description-display: flex;
  --banner-description-font-line-height: 18px;
  --banner-description-font-weight: var(--font-weight--normal);
  margin: 0 0 var(--mj-component-margin-bottom);

  @include for-desktop {
    margin: 0 0 var(--mj-component-margin-bottom);
    --banner-title-margin: 14px 0 0;
    --banner-description-margin: 14px 48px 0 0;
    --banner-description-font-line-height: 20px;

    .mb-tiny & {
      --mj-component-margin-bottom: 1.25rem;
    }
  }

  @media screen and (min-width: 768px) {
    border-radius: var(--spacer-2xs);
  }

  @media only screen and (max-width: 767px) {
    --banner-height: 285px;
    --h2-font-size: 24px;
    --font-size--base: 12px;
    --spacer-xl: 16px;
    --banner-wrapper-justify-content: flex-end;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(2deg, rgba(0, 0, 0, 0.75) 20%, rgba(0, 0, 0, 0) 62%);
  }

  .bynder-asset {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .sf-banner__wrapper {
    --banner-align-items: flex-start;

    @media only screen and (max-width: 767px) {
      --button-width: 100%;
      --button-height: auto;
      white-space: initial;
    }
  }

  .sf-banner__title {
    max-width: 50%;

    @media only screen and (max-width: 767px) {
      line-height: 1.33;
      max-width: 80%;
    }
  }

  .sf-banner__subtitle {
    font-size: 10px;
    line-height: 18px;

    @include for-desktop {
      font-size: 13px;
      line-height: 22px;
    }
  }

  .sf-banner__description {
    margin-bottom: 0;
  }

  .hero-article__button {
    --chevron-size: 1rem;
    position: absolute;
    right: var(--spacer-xl);
    bottom: var(--spacer-xl);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: var(--spacer-lg);
    width: var(--spacer-lg);

    &:before {
      content: '';
      position: absolute;
      left: -100vw;
      top: -100vw;
      width: 200vw;
      height: 200vw;
      z-index: 1;
    }

    @include for-desktop {
      width: var(--spacer-xl);
      height: var(--spacer-xl);
    }

    &:hover,
    &:focus {
      --button-background: var(--primary-color);
    }

    .sf-chevron {
      transform: translateX(-2px);
    }
  }
}
.hero-article-wrapper {
  max-width: var(--container-width-lg);
  margin: auto;
}
</style>
