<template>
  <form class="sf-header__search-form" method="get" @submit.prevent="submitSearch">
    <div
      v-click-outside="closeSearch"
      class="sf-search-bar sf-header__search"
      :class="{ 'sf-search-bar--has-term': !!term }"
    >
      <SfButton
        class="sf-search-bar__close-button sf-button--pure"
        type="button"
        :aria-label="$t('Close search')"
        data-testid="closeSearchButton"
        @click="closeSearch"
      >
        <ArrowLeftIcon />
      </SfButton>
      <input
        ref="searchInputElement"
        class="sf-search-bar__input"
        type="search"
        :placeholder="isDesktop ? $t('Search for products, ...') : $t('Search for products, categories, ...')"
        :aria-label="$t('Search')"
        name="q"
        autocomplete="off"
        :value="term"
        data-testid="searchInput"
        data-hj-allow
        @input="(e) => (term = e.target.value)"
        @keydown="handleSearch"
        @keydown.tab="hideSearch"
        @keydown.esc="closeSearch"
        @focus="showSearch"
        @click="showSearch"
      />
      <SfButton
        v-if="!!term"
        class="sf-search-bar__button sf-button--pure"
        :aria-label="$t('Search')"
        data-testid="searchButton"
        @click="closeSearch"
      >
        <span class="sf-search-bar__icon">
          <SearchIcon :aria-label="$t('Cancel')" />
        </span>
      </SfButton>
      <SfButton
        v-else
        class="sf-search-bar__button sf-button--pure"
        :aria-label="$t('Open search')"
        @click="toggleSearch"
        @keydown.tab="hideSearch"
      >
        <span class="sf-search-bar__icon">
          <SearchIcon :aria-label="$t('Search')" />
        </span>
      </SfButton>
    </div>
  </form>
</template>

<script setup>
import debounce from '~/utils/debounce'
import { clickOutside } from './../Sf/utilities/directives/click-outside/click-outside-directive.js'
import useBloomreachAutosuggestApi from '~/composables/useBloomreachAutoSuggestApi'
import { slugify } from '~/utils/stringHelpers'

import ArrowLeftIcon from '~/assets/icons/arrow-left.svg'
import SearchIcon from '~/assets/icons/search.svg'
import { storeToRefs } from 'pinia'

const props = defineProps({
  minTermLen: {
    type: Number,
    default: 1,
  },
})

const emit = defineEmits(['SearchBar:toggle', 'SearchBar:result', 'SearchBar:loading'])

const vClickOutside = clickOutside

const { isDesktop } = useDevice()
const route = useRoute()
const router = useRouter()
const term = ref('')
const result = ref(null)
const isSearchOpen = ref(false)
const searchInputElement = ref()

const { results: suggestResults, search: searchAutosuggest } = useBloomreachAutosuggestApi()
const { clearFilters } = useBloomreachDiscoveryStore()
const { $localePath } = useNuxtApp()

const showSearch = () => {
  if (!term.value?.length) return

  if (!isSearchOpen.value) {
    isSearchOpen.value = true
    emit('SearchBar:toggle', true)
    document?.body.classList.add('no-scroll')
  }
}

const hideSearch = () => {
  if (isSearchOpen.value) {
    isSearchOpen.value = false
    searchInputElement.value?.blur()
    emit('SearchBar:toggle', false)
    emit('SearchBar:result', {})
    document?.body.classList.remove('no-scroll')

    setTimeout(() => (term.value = ''), 10)
  }
}

const toggleSearch = () => {
  if (isSearchOpen.value) {
    hideSearch()
  } else {
    showSearch()
  }
}

const closeSearch = (event) => {
  if (document) {
    const searchResultsEl = document.getElementsByClassName('search')
    const wishlistSelectionEl = document.getElementsByClassName('wishlist-variant-selection-modal')
    const sidebarEl = document.getElementsByClassName('mjsf-sidebar')

    if (
      !searchResultsEl[0]?.contains(event.target) &&
      !wishlistSelectionEl[0]?.contains(event.target) &&
      !sidebarEl[0]?.contains(event.target) &&
      !event.target?.closest('.mjsf-sidebar')
    ) {
      hideSearch()
    }
  } else {
    hideSearch()
  }
}

const submitSearch = async () => {
  if (!term.value) return

  clearFilters()
  handleSearch.cancel()

  await router
    .push({
      path: `${$localePath('/catalogsearch/result/')}`,
      query: { q: term.value },
    })
    .catch(() => {})
}

const handleSearch = debounce(async () => {
  if (term.value.length < props.minTermLen) {
    hideSearch()
    return
  }
  showSearch()

  emit('SearchBar:loading', true)
  await searchAutosuggest(term.value)

  const termRegex = new RegExp(`(${term.value})`, 'gi')
  const products = suggestResults.value?.suggestionGroups?.[0].searchSuggestions?.slice(0, 6)
  const categories = suggestResults.value?.suggestionGroups?.[0].attributeSuggestions
    ?.filter((s) => s.attributeType === 'category')
    ?.map(({ name, value }) => {
      return {
        id: value,
        label: name,
        markedLabel: name.replace(termRegex, '<mark>$&</mark>'),
        textSlug: slugify(name.toLowerCase()),
        slug: slugify(value),
      }
    })

  const suggestions =
    suggestResults.value?.suggestionGroups?.[0]?.querySuggestions?.slice(0, 4).map(({ query, displayText }) => ({
      query,
      displayText,
      markedText: displayText.replace(termRegex, '<mark>$&</mark>'),
    })) || []

  result.value = {
    query: term.value,
    products,
    categories,
    suggestions,
  }

  emit('SearchBar:result', result.value)
  emit('SearchBar:loading', false)
}, 500)

watch(route, hideSearch)

defineExpose({ hideSearch })
</script>

<style lang="scss">
.sf-header__search-form {
  flex: 0 0 100%;
}

.sf-search-bar {
  transition: padding 0.25s ease;
  will-change: padding;

  .sf-search-bar__close-button {
    position: absolute;
    left: 0;
    visibility: hidden;
    opacity: 0;
    --button-width: 44px;
    --button-height: 46px;
    --button-background: var(--gray-background-color);
    --button-padding: 1px;
    --button-border-radius: 0;

    @include for-desktop {
      --button-height: 48px;

      &:hover {
        --button-background: var(--primary-color);
      }
    }
  }

  &.sf-search-bar--has-term {
    padding-left: 56px;
    transition: all 0.25s ease;

    .sf-search-bar__close-button {
      visibility: visible;
      opacity: 1;
    }
  }

  &__button {
    position: absolute;
    bottom: 0 !important;
    --button-height: 100%;

    @include for-desktop {
      --button-height: var(--spacer-3rem) !important;
      --search-bar-button-right: var(--spacer-sm);
    }
  }

  &__icon {
    height: var(--spacer-base);
  }

  &__input {
    --search-bar-border-color: var(--gray-dark-accent-color);
    --search-bar-font-size: 16px;
    padding: 0 var(--spacer-12);
    border-radius: var(--spacer-2xs);
    outline: none;

    ::placeholder {
      color: var(--black-secondary-color);
      opacity: 1;
    }

    @include for-desktop {
      padding: 0 var(--spacer-xl) 0 var(--spacer-md);
    }

    &:focus {
      --search-bar-border-color: var(--secondary-color) !important;
    }
  }
}
</style>
