<template>
  <div class="inline-container">
    <span v-for="(child, key) in props.component.getChildren()" :key="key">
      <br-component :component="child" />
    </span>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  component: null,
})
</script>
