<template>
  <div v-if="component" :class="isPreview ? 'has-edit-button' : ''">
    <br-manage-content-button :content="content" />
    <div
      class="header-notification"
      :style="{ backgroundColor }"
      :aria-hidden="isHeaderNotificationVisible && content && loaded && !isPreview ? undefined : true"
    >
      <div class="header-notification__content">
        <div class="header-notification__message" v-html="notification.value || '&nbsp;'" />
        <button
          v-if="notification.copyValue"
          class="header-notification__copy-button sf-button sf-button--pure"
          @click="copy"
        >
          <CopyIcon />
        </button>
      </div>
      <ClientOnly>
        <Countdown
          v-if="showCountDown"
          v-slot="countDown"
          class="header-notification__countdown"
          :endDate="new Date(countDown)"
        >
          <span v-if="countDown.days">{{ countDown.days }}d</span>
          <span v-if="countDown.hours">{{ countDown.hours }}h</span>
          <span v-if="countDown.minutes">{{ countDown.minutes }}m</span>
          <span v-if="countDown.seconds">{{ countDown.seconds }}s</span>
        </Countdown>
      </ClientOnly>
      <button class="header-notification__button sf-button sf-button--pure" :aria-label="$t('Close')" @click="hide">
        <TimesIcon />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Component, Page } from '@bloomreach/spa-sdk'
import TimesIcon from '~/assets/icons/times.svg'
import CopyIcon from '~/assets/icons/copy.svg'

interface Props {
  component: Component
  page: Page
}

const props = defineProps<Props>()

const uiState = useUiState()
const { showHeaderNotification, hideHeaderNotification } = uiState
const { isHeaderNotificationVisible } = storeToRefs(uiState)
const loaded = ref(false)

const content = computed(() => {
  const { document } = props.component?.getModels()
  return props.page.getContent(document)
})

const model = computed(() => {
  const data = content?.value?.getData()

  return {
    ...data,
    [data?.ctaLink ? 'ctaLink' : null]: props.page.getContent(data?.ctaLink)?.getUrl(),
  }
})

const isPreview = computed(() => props.page?.isPreview())

const notification = computed(() => {
  if (!model || !model.value) return
  const value = model.value.content?.value
  const message = {
    value,
    copyValue: null,
  }
  if (!value) return message
  const messageArr = value.split(/&#60;copy&#62;|&#60;\/copy&#62;/)
  if (messageArr.length > 1) {
    message.value = `${messageArr[0]}<u>${messageArr[1]}</u>${messageArr[2]}`
    message.copyValue = messageArr[1]
  }
  return message
})

const countDown = computed(() => model?.value?.dateTimeUntil)

const showCountDown = computed(() => countDown?.value && countDown?.value > Date.now())

const backgroundColor = computed(() => model?.value?.backgroundColor)

const hide = () => {
  const today = new Date()
  const tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)

  useCookie('hideHeaderNotification', { expires: tomorrow }).value = 'true'

  hideHeaderNotification()
}

const copy = () => {
  navigator.clipboard.writeText(notification?.value?.copyValue)
}

onMounted(() => {
  if (useCookie('hideHeaderNotification').value) {
    hideHeaderNotification()
  } else if (content.value) {
    showHeaderNotification()
  }
  loaded.value = true
})
</script>

<style lang="scss">
.hst-container-item:has(.header-notification) {
  grid-column: 1 / -1;
}

.header-notification {
  position: relative;
  background-color: var(--primary-color);
  text-align: center;
  padding: var(--spacer-sm) var(--spacer-3rem) var(--spacer-sm) var(--spacer-sm);
  line-height: 1.125em;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: var(--spacer-xs);
  font-size: var(--global-font-size);

  &[aria-hidden] {
    display: none;
  }

  @include for-desktop {
    flex-direction: row;
    padding: var(--spacer-sm) var(--spacer-lg) var(--spacer-sm) var(--spacer-sm);
    gap: var(--spacer-sm);
  }

  p {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0;
    font-size: inherit;

    img {
      margin: -12px 0; // Fix so the icon doesn't push the height unnecessarily
    }
  }

  a {
    color: var(--black-color);
    text-decoration: underline;
  }

  &__content {
    display: flex;
    gap: 12px;
  }

  &__button {
    position: absolute;
    top: var(--spacer-12);
    right: var(--spacer-12);
    width: var(--spacer-base);
    height: var(--spacer-base);

    @include for-desktop {
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
    }
  }

  &__copy-button {
    margin: -3px 0;
    height: auto;
  }

  &__countdown {
    display: flex;
    gap: var(--spacer-xs);
  }
}
</style>
