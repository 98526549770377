<template>
  <div class="highlighted-articles" :data-columns="columns">
    <SfBanner
      v-for="(banner, index) in banners"
      :key="index"
      class="highlighted-article"
      :title="banner.bannerTitle"
      :subtitle="banner.category"
      :description="banner.bannerDescription ? truncateWords(banner.bannerDescription, 25) : ''"
      :button-text="banner.ctaTitle"
    >
      <template #description>
        <p v-dompurify-html="banner.bannerDescription || ''" class="sf-banner__description" />
      </template>
      <template #call-to-action>
        <BynderAsset :data="banner.asset" size="(max-width: 1023px) 100vw, 400px" />
        <SfLink
          class="sf-button sf-button--arrow"
          :aria-disabled="false"
          :link="localePath(banner.ctaUrl || banner.ctaLink)"
        >
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 13.0001L7 7.00012L1 1.00012"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </SfLink>
      </template>
    </SfBanner>
  </div>
</template>

<script setup lang="ts">
import { truncateWords } from '@/utils/stringHelpers'

interface Props {
  banners: any[]
}

const props = defineProps<Props>()

const columns = computed(
  () => (props.banners.length >= 2 ? (props.banners.length % 2 === 0 ? 2 : 3) : 2), // When carrot of two equals zero, show two columns. Otherwise show three.
)

const localePath = useLocalePath()
</script>

<style lang="scss">
.highlighted-articles {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacer-xs);
  max-width: var(--container-width-lg);
  margin: 0 0 var(--mj-component-margin-bottom);

  @include for-desktop {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacer-md);
    margin-left: auto;
    margin-right: auto;

    .mb-md & {
      --mj-component-margin-bottom: var(--margin-bottom-xxl) !important;
    }
  }

  &[data-columns='2'] {
    grid-template-columns: repeat(2, 1fr);

    .sf-card {
      border-radius: var(--spacer-2xs);
    }
  }
}

.highlighted-article {
  position: relative;
  overflow: hidden;
  border-radius: var(--spacer-2xs);
  --banner-align-items: flex-end;
  --banner-color: var(--white-color);
  --banner-wrapper-width: 100%;
  --button-background: var(--primary-color);
  --button-color: var(--black-color);
  --button-text-transform: none;
  --banner-height: 400px;
  --banner-subtitle-text-transform: none;
  --banner-subtitle-font: var(--font-family--secondary);
  --banner-subtitle-margin: 0 0 var(--spacer-2xs) 0;
  --font-size--base: 14px;

  --banner-title-font-family: var(--font-family--primary);
  --banner-title-font-weight: var(--font-weight--semibold);
  --banner-title-font-size: 14px;
  --banner-title-font-line-height: 20px;
  --banner-title-margin: 0 0 var(--spacer-2xs) 0;
  --banner-title-text-transform: none;
  --banner-description-display: flex;
  --banner-description-margin: 0;
  --banner-description-font-line-height: 18px;
  --banner-description-font-weight: var(--font-weight--normal);

  @include for-desktop {
    --banner-title-font-size: 21px;
    --banner-title-font-line-height: 26px;
    --banner-title-margin: 0 0 var(--spacer-xs) 0;
    --banner-subtitle-margin: 0 0 var(--spacer-xs) 0;
    --banner-description-font-line-height: 1.4286;
  }

  @media only screen and (max-width: 767px) {
    --banner-height: 305px;
    --h2-font-size: 24px;
    --font-size--base: 12px;
    --spacer-xl: 16px;
    --banner-wrapper-justify-content: flex-end;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(2deg, rgba(0, 0, 0, 0.75) 20%, rgba(0, 0, 0, 0) 62%);
  }

  .bynder-asset {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .sf-banner__wrapper {
    --banner-align-items: flex-start;

    &.sf-button {
      --button-height: auto;
      --button-width: 100%;
      white-space: initial;
    }
  }

  .sf-banner__title {
    --banner-title-font-weight: var(--font-weight--semibold);
    text-align: left;
  }

  .sf-banner__subtitle {
    font-size: 10px;
    line-height: 18px;
  }

  .sf-banner__description {
    margin-bottom: 0;

    @media only screen and (max-width: 767px) {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .sf-button--arrow {
    --chevron-size: 1rem;
    position: absolute;
    right: var(--spacer-xl);
    bottom: var(--spacer-xl);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: var(--spacer-lg);
    width: var(--spacer-lg);
    opacity: 0;

    &:before {
      content: '';
      position: absolute;
      left: -50vw;
      top: -50vw;
      width: 100vw;
      height: 100vw;
      z-index: 1;
    }

    @include for-desktop {
      width: var(--spacer-xl);
      height: var(--spacer-xl);
    }

    &:hover,
    &:focus {
      --button-background: var(--primary-color);
    }

    .sf-chevron {
      transform: translateX(-2px);
    }
  }
}
</style>
