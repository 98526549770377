<template>
  <div v-if="banners.length" class="image-text-banners">
    <div v-for="(banner, index) in banners" :key="index" class="image-text-banner">
      <BynderAsset
        v-if="banner.asset"
        class="image-text-banner__image"
        :data="banner.asset"
        size="(min-width: 1024px) 762px, 100vw"
        :ratio="0.5625"
      />
      <div class="image-text-banner__text">
        <br-manage-content-button :content="banner.content" />
        <h2 v-if="banner.bannerTitle" v-html="banner.bannerTitle" />

        <div v-dompurify-html="banner.bannerDescriptionHtml" />
        <nuxt-link
          v-if="banner.ctaLink || banner.ctaUrl"
          class="sf-button"
          :class="{ 'sf-button--invisible': !banner.ctaTitle }"
          :aria-disabled="false"
          :to="$localePath(banner.ctaLink || banner.ctaUrl)"
        >
          {{ banner.ctaTitle }}
        </nuxt-link>
      </div>
      <br-manage-content-button :content="banner.content" />
    </div>
  </div>
</template>

<script setup lang="ts">
const { $localePath } = useNuxtApp()

defineProps({
  banners: {
    type: Object as PropType<Record<string, any>[]>,
    default: [],
  },
})
</script>

<style lang="scss">
.image-text-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--mj-component-margin-bottom);
  background-color: var(--sand-background-color);
  overflow: hidden;

  @include for-desktop {
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  * {
    box-sizing: border-box;
  }

  &__text {
    position: relative;
    flex: 0 0 40%;
    padding: 32px 16px 40px; // 1.625rem 2.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include for-desktop {
      padding: 1.625rem 4rem;
    }

    h2 {
      margin-bottom: var(--spacer-xs);
      line-height: var(--h2-line-height);

      @include for-desktop {
        margin-bottom: var(--spacer-sm);
      }
    }

    p {
      margin-top: 0;
      margin-bottom: var(--spacer-sm);

      @include for-desktop {
        margin-bottom: var(--spacer-base);
      }

      a {
        text-decoration: underline;
      }
    }
  }

  &__image {
    position: relative;
    flex: 0 0 60%;
    margin: 0;
    padding: 0;
  }

  .sf-button {
    --button-color: var(--white-color);
    --button-background: var(--black-color);
    --button-text-transform: none;
    --button-font-weight: normal;
    --button-transition: none;
    --button-font-weight: var(--font-weight--semibold);
    --c-link-hover: #fff;
    --button-height: var(--spacer-3rem);
    position: static;

    @include for-desktop {
      --button-height: unset;
    }

    &:before {
      content: '';
      position: absolute;
      /*left: -100vw;
      right: -100vw;
      top: -100vw;
      bottom: -100vw;*/
      width: auto;
      height: auto;
      z-index: 1;
    }

    &:hover,
    &:focus {
      --button-background: var(--black-secondary-color);
      --button-border-color: var(--black-secondary-color);
      --button-color: var(--white-color);
    }
  }
}

.banner-type-image-and-text-left {
  .image-text-banner__text {
    @include for-desktop {
      order: -1;
    }
  }
}
</style>
