<template>
  <div class="image-text-cards" data-columns="2">
    <CardItem
      v-for="(banner, index) in props.banners"
      :key="index"
      :title="banner.bannerTitle"
      :description="banner.bannerDescription"
      :category="getCategory(banner)"
      :image="banner.bannerImage"
      :asset="banner.asset"
      :link="banner.ctaUrl || banner.ctaLink"
      class="sf-card--article-latest"
    />
  </div>
</template>

<script setup lang="ts">
import CardItem from "~/components/Bloomreach/Banner/CardItem.vue"

interface Props {
  banners: any[]
}

const props = defineProps<Props>()
const getCategory = (banner) =>
  banner?.hideCategory ? "" : banner.categoryManually ? banner.categoryManually : banner.category || ""
</script>
