<template>
  <div>
    <slot :days="days" :hours="hours" :minutes="minutes" :seconds="seconds"></slot>
  </div>
</template>

<script setup>
const props = defineProps({
  endDate: Date,
  negative: false,
})

const emit = defineEmits(['endTime'])

const now = ref(new Date())
const timer = ref(null)

const days = computed(() => Math.trunc((props.endDate - now.value) / 1000 / 3600 / 24))
const hours = computed(() => Math.trunc((props.endDate - now.value) / 1000 / 3600) % 24)
const minutes = computed(() => Math.trunc((props.endDate - now.value) / 1000 / 60) % 60)
const seconds = computed(() => Math.trunc((props.endDate - now.value) / 1000) % 60)

watch(
  () => props.endDate,
  (newVal) => {
    if (timer.value) {
      clearInterval(timer.value)
    }

    timer.value = setInterval(() => {
      now.value = new Date()
      if (props.negative) {
        return
      }

      if (now.value > newVal) {
        now.value = newVal
        emit('endTime')
        clearInterval(timer.value)
      }
    }, 1000)
  },
  { immediate: true },
)

onBeforeUnmount(() => {
  clearInterval(timer.value)
})
</script>
