<template>
  <nav class="top-nav grid-container" :aria-expanded="isMobileMenuOpen ? 'true' : false">
    <div class="top-nav__background" @click="toggleNavMenu"></div>
    <div class="top-nav__inner">
      <slot />
      <div class="top-nav__arrow"></div>
    </div>
    <SfButton class="sf-button--pure top-nav__close-button smartphone-only" @click="toggleNavMenu">
      <TimesIcon />
    </SfButton>
  </nav>
</template>

<script setup lang="ts">
import { useUiHelpers } from '~/composables'
import TimesIcon from '~/assets/icons/times.svg'

const uiState = useUiState()
const { toggleMobileMenu } = uiState
const { isMobileMenuOpen } = storeToRefs(uiState)
const { toggleMobileMenuOpenClassOnBody } = useUiHelpers()

const toggleNavMenu = () => {
  toggleMobileMenu()
  toggleMobileMenuOpenClassOnBody()
}
</script>

<style lang="scss">
.top-nav {
  position: absolute;
  padding: 0;

  @media screen and (min-width: 1080px) and (max-width: 1180px) {
    font-size: 92.5%;
  }

  @media screen and (min-width: 1024px) and (max-width: 1079px) {
    font-size: 87.5%;
  }

  @include for-mobile {
    position: fixed;
    display: flex;
    color: var(--black-color);
    top: 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
    min-height: 100vh;
    z-index: 10;
    transition: all 0.25s ease;

    &__inner {
      position: relative;
      overflow: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      height: 100vh;
      height: 100dvh;
      z-index: 1;

      @include for-mobile {
        background-color: var(--white-color);
        transform: translate(-100%, 0);
        transition: all 0.35s ease;
        will-change: transform;
        width: calc(100vw - var(--spacer-xl));
      }
    }

    ul.top-nav__menu {
      overflow: auto;
      padding: 0 var(--spacer-sm) var(--spacer-sm);

      &.is-expanded {
        @include for-mobile {
          overflow: hidden;
          height: 100vh;
          height: 100dvh;
        }
      }

      > li {
        > .nav-link,
        > a {
          background-color: var(--gray-background-color);
          border-radius: var(--spacer-2xs);
        }
      }

      ul {
        &[aria-expanded='true'] {
          @include for-mobile {
            overflow: hidden;
            height: 100vh;
            height: 100dvh;
          }
        }
      }
    }

    &[aria-expanded='true'] {
      &:before {
        opacity: 1;
      }

      @include for-mobile {
        pointer-events: initial;

        ul.top-nav__submenu {
          display: block;

          @supports (-webkit-touch-callout: none) {
            padding-bottom: 6rem;
          }
        }
      }

      .top-nav__close-button {
        right: 0;
        opacity: 1;
      }

      .top-nav__background {
        pointer-events: all;
        opacity: 1;
      }

      > .top-nav__inner {
        transform: translate(0);
      }
    }
  }

  @include for-desktop {
    position: relative;
    visibility: visible;
    width: 100%;
    opacity: 1;
    padding: 0;
    background: var(--black-color);
    border: 1px solid var(--black-color);
    border-width: 1px 0;
    color: var(--white-color);
  }

  &__close-button {
    --button-border-radius: 0;
    --button-size: var(--spacer-xl);
    position: fixed;
    right: calc(0% - var(--button-size));
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
    color: var(--black-color);
    opacity: 0;
    transition: all 0.35s ease;
  }

  &__background {
    grid-column: 1 / -1;
    pointer-events: none;
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.25s ease;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    @include for-desktop {
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
    }

    li {
      line-height: var(--xs-line-height);
      font-weight: var(--font-weight--semibold);
      padding: 0;
      margin: 0 0 var(--spacer-12);

      @include for-desktop {
        margin: 0;
        position: relative;

        &:last-child {
          margin-right: 0;
        }
      }

      .nav-link,
      a {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        color: var(--black-color);
        padding: var(--spacer-sm) var(--spacer-xs) var(--spacer-sm) var(--spacer-sm);

        @include for-desktop {
          color: var(--white-color);
          padding: var(--spacer-xs) 0;
          background: none;
          min-height: var(--spacer-3rem);
          transition: color 0.25s ease;
        }
      }

      &:before {
        display: none;
      }

      &:hover {
        @include for-desktop {
          > a {
            color: var(--secondary-color);
          }

          > ul {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      .nav-link__icon {
        width: var(--spacer-base);
        height: var(--spacer-base);
        margin-top: -4px;
        margin-bottom: -4px;

        @include for-desktop {
          display: none;
        }
      }

      .uitgelicht-item {
        background: var(--primary-color);
        color: var(--black-secondary-color);
        font-size: var(--font-size-xs);
        line-height: var(--global-line-height);
        text-transform: uppercase;
        text-wrap: nowrap;
        padding: var(--spacer-xs);
        border-radius: var(--spacer-2xs);
        pointer-events: none;
        z-index: 0;
        margin-left: var(--spacer-sm);
        margin-right: 0;
        display: inline-block;
        margin-top: -8px;
        margin-bottom: -8px;
      }
    }
  }

  ul.top-nav__submenu {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background: var(--white-color);
    color: var(--black-color);
    z-index: 1;
    list-style: none;
    margin: 0;
    padding: 0 var(--spacer-sm) var(--spacer-sm);
    overflow: auto;
    max-height: 100vh;
    max-height: 100dvh;
    transition: all 0.25s ease;

    &--expanded {
      max-height: unset;

      @include for-mobile {
        transform: translate(-100%, 0);
        visibility: visible;
        opacity: 1;
        overflow-x: hidden;
      }
    }

    @include for-mobile {
      display: none;
    }

    @include for-desktop {
      display: block;
      width: calc(100vw - 48px);
      height: auto;
      max-width: 504px;
      max-height: unset;
      visibility: hidden;
      overflow: hidden;
      opacity: 0;
      left: 50%;
      top: 100%;
      padding: var(--spacer-xl);
      transform: translate(-50%, 1px);
    }

    @include for-desktop {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: var(--primary-color);
        height: var(--spacer-xs);
        pointer-events: none;
      }
    }

    &--level2 {
      transition: transform 0.25s ease;

      @include for-desktop {
        box-shadow: 0px 4px 11px rgba(29, 31, 34, 0.1);
      }
    }

    ul.top-nav__submenu {
      transform: none;
      transition: transform 0.25s ease;

      &--expanded {
        transform: translate(-100%, 0);
        visibility: visible;
        opacity: 1;
      }
    }

    li {
      position: static;
      line-height: var(--global-line-height);
      border-bottom: 1px solid var(--gray-dark-accent-color);
      margin-right: 0;

      &.nav-item__title {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        font-family: var(--font-family--secondary);
        font-size: var(--h2-font-size);
        font-weight: var(--font-weight--normal);
        line-height: var(--sm-line-height);
        height: var(--spacer-xl);
        margin-bottom: var(--spacer-sm);
        border-bottom: 0;

        @include for-desktop {
          height: auto;
          padding-bottom: var(--spacer-md);
          margin-bottom: 0;
        }

        svg {
          margin-top: var(--spacer-2xs);
          margin-right: var(--spacer-sm);

          @include for-desktop {
            margin-right: var(--spacer-base);
          }
        }
      }

      &.nav-item__back-link {
        cursor: pointer;
      }

      .nav-link {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        color: var(--black-color);
        cursor: pointer;
        padding: var(--spacer-sm);

        @include for-desktop {
          padding: var(--spacer-sm) 0;
        }

        button {
          display: block;
          flex: 0 0 var(--spacer-md);
          padding: 0;
          border: 0;
          margin: 0;
          appearance: none;
          background: none;
          pointer-events: none;
          height: var(--spacer-md);
        }

        .nav-link__icon {
          display: block;
        }
      }

      &:hover {
        > .nav-link {
          @include for-desktop {
            color: var(--secondary-color);
          }
        }
      }

      &.active {
        > .nav-link {
          color: var(--secondary-color);
        }
      }

      ul {
        position: absolute;
        top: 0;
        left: 100%;
      }
    }
  }

  .top-nav__header {
    height: var(--spacer-xl);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacer-sm);

    &-title {
      font-family: var(--font-family--secondary);
      font-size: var(--h2-font-size);
      line-height: var(--global-line-height);
    }

    @include for-desktop {
      display: none;
    }
  }

  .top-nav__arrow {
    position: absolute;
    display: none;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--primary-color);
    top: -10px;
    left: 0;
    transition:
      all 0.25s,
      opacity 0.1s,
      transform 0.1s;
    transform: translate(50vw, 50px);
    opacity: 0;
    pointer-events: none;

    @include for-desktop {
      display: block;

      &.open {
        opacity: 1;
      }
    }
  }

  .store-switcher {
    --button-height: var(--spacer-lg);
  }
}

body.is-nav-menu-open {
  @include for-mobile {
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    overflow: hidden;
  }
}
</style>
