<template>
  <br-page v-if="visible" :configuration :page :mapping>
    <slot :data />
  </br-page>
</template>

<script setup lang="ts">
import { initialize } from '@bloomreach/spa-sdk'
import { buildConfiguration } from '~/utils/buildConfiguration'
import useGTMComposable from '~/composables/useGTM'

const { mapping } = useBloomreachComponents()
const configuration = buildConfiguration()
const { data, refresh } = await useAsyncData('pageData', async () => await setPageData())
const route = useRoute()
const { trackPageEvent } = useGTMComposable()

let page = ref(initialize(configuration, data.value?.page))

// Hack to reinitialize the page when the website is used via Bloomreach CMS
const visible = ref(!route.query.token ? true : false)
onMounted(async () => {
  if (route.query.token) {
    page.value = await initialize(configuration)
    visible.value = true
  }
  trackPageEvent()
})

// When the data changes (new page is loaded), update the configuration and initialize the page
watch(data, (newValue, oldValue) => {
  if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
    configuration.path = newValue.path
    page.value = initialize(configuration, data.value?.page)
  }
})

defineExpose({ refresh })
</script>
