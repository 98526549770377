<template>
  <div v-if="component" class="footer-cta-reviews" :class="{ 'has-edit-button': isPreview }">
    <h4>{{ content.title }}</h4>
    <FeedbackCompany :id="content.backendId" :image="content.image" />
    <br-manage-content-button :content="document" />
  </div>
</template>

<script setup lang="ts">
const { component, page } = defineProps({
  component: null,
  page: null,
})

const document = computed(() => {
  const { document } = component?.getModels()
  return page?.getContent(document)
})

const content = computed(() => {
  const { document } = component?.getModels()
  const content = page.getContent(document)
  return content?.model?.data || {}
})

const isPreview = computed(() => page?.isPreview())
</script>

<style lang="scss">
.footer-cta-reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>
