<template>
  <div class="announcement-header">
    <BynderAsset class="announcement-header__image" :data="image" />

    <ClientOnly>
      <Countdown
        v-slot="countDown"
        class="announcement-header__countdown"
        :endDate="new Date(dateTimeUntil)"
        :negative="true"
        :style="{ color: colorDateTimeUntil }"
      >
        {{ $t('Ends in') }}&nbsp;
        <span v-if="countDown.days">{{ countDown.days }}d</span>
        <span v-if="countDown.hours">{{ countDown.hours }}h</span>
        <span v-if="countDown.minutes">{{ countDown.minutes }}m</span>
        <span v-if="countDown.seconds">{{ countDown.seconds }}s</span>
      </Countdown>
    </ClientOnly>

    <NuxtLink v-if="internalLink" class="announcement-header__link" :to="localePath(internalLink)"></NuxtLink>
    <a v-else class="announcement-header__link" :href="ctaUrl"></a>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  component: null,
  page: null,
})

const { document } = props.component?.getModels()
const { colorDateTimeUntil, ctaLink, ctaUrl, dateTimeUntil, image } = props.page?.getContent(document)?.getData() || {}

const internalLink = props.page?.getContent(ctaLink)?.getUrl()
</script>

<style lang="scss">
.hst-container-item:has(.announcement-header) {
  grid-column: 1 / -1;
}
</style>

<style lang="scss" scoped>
.announcement-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacer-base);
  height: 17vw;
  background-color: var(--gray-background-color);
  font-size: var(--font-size-md);
  font-weight: 600;
  color: #fff;

  @include for-desktop {
    height: 8vw;
    font-size: var(--font-size-lg);
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__countdown {
    position: relative;
    display: flex;
    gap: var(--spacer-xs);
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
</style>
