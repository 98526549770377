<template>
  <div class="container">
    <section class="store-grid">
      <BloomreachStoreGridItem v-for="(store, index) in stores" :key="index" :store="store" />
    </section>
  </div>
</template>

<script setup lang="ts">
import type { Document, Pagination as BrPagination, Page, Component } from '@bloomreach/spa-sdk'
import type { Store, StoreDocument, StoreAddress, StoreOpeningHours } from '~/types/storeGrid'

interface BrProps {
  page: Page
  component: Component
}

const props = defineProps({
  component: null,
  page: null,
})

const isPreview = props.page.isPreview()
const { pagination: paginationRef } = props.component.getModels()
const pagination = paginationRef && props.page.getContent<BrPagination>(paginationRef)

const documents = pagination?.getItems().map((ref) => props.page.getContent<Document>(ref)) || []

const stores: Store[] = documents.map((doc) => {
  const {
    storeName,
    storeImage,
    displayName,
    streetName,
    houseNumber,
    addition,
    postalCode,
    city,
    country,
    bookingUrl,
    bookingImage,
    openingHours: openingHoursRefs,
    adjustedOpeningHours: adjustedOpeningHoursRefs,
  } = doc.getData<StoreDocument>()
  const openingHours = openingHoursRefs
    .map((ref) => props.page.getContent<Document>(ref))
    .filter((x) => !!x)
    .map((doc: Document) => {
      const { day, hours, note } = doc.getData<StoreOpeningHours>() || {}
      return <StoreOpeningHours>{ day, hours, note }
    })

  const adjustedOpeningHours = adjustedOpeningHoursRefs
    .map((ref) => props.page.getContent<Document>(ref))
    .filter((x) => !!x)
    .map((doc: Document) => {
      const { day, hours, note } = doc.getData<StoreOpeningHours>() || {}
      return <StoreOpeningHours>{ day, hours, note }
    })

  return <Store>{
    name: displayName,
    address: <StoreAddress>{
      streetName,
      houseNumber,
      addition,
      postalCode,
      city,
      country,
    },
    storeName,
    storeImage: getBynderImageUrl(storeImage),
    storeUrl: doc.getUrl(),
    bookingUrl,
    bookingImage,
    openingHours,
    adjustedOpeningHours,
    document: doc,
  }
})
</script>

<style lang="scss">
.store-grid {
  position: relative;
  margin-bottom: var(--spacer-base);

  @include for-desktop {
    display: grid;
    column-gap: var(--spacer-md);
    row-gap: var(--spacer-lg);
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: var(--spacer-xl);
    width: calc(100% - (var(--spacer-md) * 2));
  }
}
</style>
