<template>
  <div v-if="component" class="article-feed" :class="{ 'has-edit-button': isPreview }" data-testid="article-feed">
    <header v-if="title" class="article-feed__header">
      <h2 class="article-feed__title" v-html="title" />
      <HTMLContent v-if="content" class="article-feed__description" :content="content.value" />
    </header>

    <div class="article-feed__items">
      <CardItem
        v-for="(article, index) in articles"
        :key="index"
        :title="article.title"
        :description="article.description"
        :category="article.category"
        :image="article.image"
        :link="article.link"
        :asset="article.asset"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Document, Pagination as BrPagination, Page, Component } from '@bloomreach/spa-sdk'
import getBynderImageUrl from '~/utils/bynderImage'
import CardItem from '~/components/Bloomreach/Banner/CardItem.vue'

interface BrProps {
  page: Page
  component: Component
}

interface ArticleDocument {
  id: string
  categoryManually: string
  hideCategory: boolean
  category: {
    selectionValues: {
      key: string
      label: string
    }[]
  }
  contentType: string
  date: number
  description: string
  displayName: string
  excludeFromFeed: boolean
  image: string
  localeString: string
  name: string
  title: string
}

export interface Article {
  title: string
  category: string
  categorySlug: string
  date: number
  description: string
  image: string
}

const props = defineProps({
  component: null,
  page: null,
})

const { page, component } = <BrProps>props
const isPreview = page.isPreview()
const { pagination: paginationRef } = component.getModels()
const document = page.getDocument<Document>()
const { articleLimit } = component.model.meta.paramsInfo
const title = computed(() => {
  return component.model.meta.paramsInfo.title
})
const { content } = document.getData()
const pagination = paginationRef && page.getContent<BrPagination>(paginationRef)
const localePath = useLocalePath()

const documents = pagination
  ?.getItems()
  .map((ref) => page.getContent<Document>(ref))
  .filter((doc: Document) => !doc.getData<ArticleDocument>().excludeFromFeed)
  .slice(0, articleLimit ?? 100)

const articles: Article[] = documents?.map((doc) => {
  const {
    title,
    category,
    date,
    description,
    image,
    titleArticleFeed,
    descriptionArticleFeed,
    categoryManually,
    hideCategory,
  } = doc.getData<ArticleDocument>()

  return <Article>{
    title: titleArticleFeed ? titleArticleFeed : title,
    category: hideCategory ? '' : categoryManually ? categoryManually : category.selectionValues[0]?.label,
    categorySlug: category.selectionValues[0].key,
    date,
    description: descriptionArticleFeed ? descriptionArticleFeed : description,
    image: getBynderImageUrl(image),
    link: localePath(doc.getUrl()),
    asset: image,
  }
})
</script>

<style lang="scss">
.article-feed {
  --article-feed-gap: var(--spacer-xs);
  --article-feed-columns: 2;
  position: relative;
  margin-bottom: 34px;

  @include for-desktop {
    --article-feed-gap: var(--spacer-sm);
    --article-feed-columns: 3;
    max-width: var(--global-width--lg);
    margin-left: auto;
    margin-right: auto;
  }

  &__header {
    text-align: center;
    margin-bottom: var(--spacer-base);
  }

  &__title {
    margin-bottom: var(--spacer-xs);
    line-height: var(--sm-line-height);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__description {
    margin-bottom: 0;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(var(--article-feed-columns), 1fr);
    gap: var(--article-feed-gap);
  }
}
</style>
