<template>
  <li
    v-if="item"
    class="nav-item"
    :class="className"
    :aria-expanded="isExpanded"
    @mouseenter="showAndMoveArrow"
    @mouseleave="hideArrow"
  >
    <NuxtLink
      v-if="linkType === TYPE_LINK_INTERNAL"
      class="nav-link"
      :class="[getLinkClassName(), { 'has-label': formattedLabel }]"
      :to="useNativeLinkEvent() ? formattedUrl : null"
      :external="linkType !== TYPE_LINK_INTERNAL"
      :data-href="url"
      :data-menu="`menu-${index}`"
      @click.native="toggleExpanded"
    >
      <span v-html="formattedName"></span>
      <span v-if="formattedLabel" :class="[formattedClass, 'uitgelicht-item']">{{ formattedLabel }}</span>
      <ChevronRightIcon v-if="subMenuItems.length" class="nav-link__icon" />
    </NuxtLink>

    <component
      :is="url ? 'a' : 'span'"
      v-else
      class="nav-link"
      :class="[getLinkClassName(), { 'has-label': formattedLabel }]"
      :href="url"
      :data-menu="`menu-${index}`"
      @click="toggleExpanded"
    >
      <span v-html="formattedName"></span>
      <span v-if="formattedLabel" :class="[formattedClass, 'uitgelicht-item']">{{ formattedLabel }}</span>
      <ChevronRightIcon v-if="subMenuItems.length" class="nav-link__icon" />
    </component>

    <TopNavNestedMenu
      v-if="showNestedMenu"
      ref="submenu"
      :items="subMenuItems"
      :level="nextLevel"
      :menuId="`${index}`"
      :title="itemName"
      :isOpen="isExpanded"
      @collapse="collapse"
    />
  </li>
</template>

<script setup>
import { TYPE_LINK_EXTERNAL, TYPE_LINK_INTERNAL } from '@bloomreach/spa-sdk'
import ChevronRightIcon from '~/assets/icons/chevron-right-alt.svg'

const mobileLabelIdentifier = 'mobile-only'
const desktopLabelIdentifier = 'desktop-only'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  index: {
    type: [Number, String],
    required: true,
  },
  level: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(['setMinHeight', 'opened', 'closed'])

const { toggleMobileMenu } = useUiState()
const { toggleMobileMenuOpenClassOnBody } = useUiHelpers()
const { isDesktop } = useDevice()
const isExpanded = ref(false)
const submenu = ref(null)

const sanitizeTitle = (name) => name.replace(/(<([^>]+)>)/gi, '').trim()

const url = props.item?.getUrl()
const formattedUrl = formatUrl(url)
const linkType =
  typeof formattedUrl === 'string' && formattedUrl.search(/(^\/|^#)/g) === -1 ? TYPE_LINK_EXTERNAL : TYPE_LINK_INTERNAL
const propsItemName = props.item?.getName()
const itemName = sanitizeTitle(propsItemName)
const subMenuItems = props.item?.getChildren() || []
const nextLevel = (props.level || 1) + 1

const setMenuMinHeight = (height) => {
  emit('setMinHeight', height)
}

const hideArrow = () => {
  if (props.level === 1) {
    document.querySelector('.top-nav__arrow')?.classList.remove('open')
  }
}

const showAndMoveArrow = (event) => {
  const arrow = document.querySelector('.top-nav__arrow')
  const target = event.currentTarget
  const rect = target.getBoundingClientRect()

  if (subMenuItems?.length) {
    if (arrow) {
      arrow.classList.add('open')
      arrow.style.transform = `translate(${rect.x + rect.width / 2 - 10}px, 50px)`
    }
  } else {
    hideArrow()
  }
}

const useNativeLinkEvent = () => {
  if (isDesktop) {
    return !subMenuItems.length || props.level === 1
  }
  return !subMenuItems.length
}

const className = computed(() => {
  return {
    expanded: isExpanded.value,
    'has-children': subMenuItems.length,
    active: props.item?.isSelected(),
  }
})

const toggleExpanded = (event) => {
  if (useNativeLinkEvent()) {
    if (!isDesktop) {
      toggleMobileMenu()
      toggleMobileMenuOpenClassOnBody()
    }
    return
  }

  event.preventDefault()
  isExpanded.value = !isExpanded.value
  setMenuMinHeight(`${submenu.value?.$el.clientHeight}px` || '100%')
  emit(isExpanded.value ? 'opened' : 'closed')
}

const getLinkClassName = () => {
  return {
    'nav-link--disabled': !url,
    'nav-link--external': linkType === TYPE_LINK_EXTERNAL,
    'nav-link--internal': linkType === TYPE_LINK_INTERNAL,
    'nav-link--expandable': subMenuItems?.length,
  }
}

const formatLink = () => {
  const formattedName = propsItemName
    .replace(/\{(.*)\}/, '')
    .replace(/\_(.*)\_/, '<i>$1</i>')
    .replace(/\*(.*)\*/, '<b>$1</b>')
    .replace(/\~(.*)\~/, '<s>$1</s>')
    .replace(/\<p\>(.*)<\/p\>/, '$1')

  const label = propsItemName.match(/\{(.*)\}/, '$1')?.[1]
  if (!label) return { name: formattedName }

  let labelClass = ''
  if (label) {
    if (label.includes(mobileLabelIdentifier)) labelClass = mobileLabelIdentifier
    if (label.includes(desktopLabelIdentifier)) labelClass = desktopLabelIdentifier
  }
  const labelClean = label.replace(mobileLabelIdentifier, '').replace(desktopLabelIdentifier, '').trim()
  return {
    name: formattedName,
    label: labelClean,
    class: labelClass,
  }
}

const formattedName = formatLink().name
const formattedClass = formatLink().class
const formattedLabel = formatLink().label

const collapse = () => {
  isExpanded.value = false
  setMenuMinHeight('100%')
  emit('closed')
}

const showNestedMenu = computed(
  () => (subMenuItems.length && isDesktop) || (!isDesktop && subMenuItems.length && isExpanded.value),
)
</script>

<style lang="scss">
.nav-item {
  strong,
  b {
    font-weight: 800;
  }
}

.top-nav ul li a,
.top-nav ul li span {
  .mobile-only {
    @include for-desktop {
      display: none;
    }
  }

  .desktop-only {
    @include for-mobile {
      display: none;
    }
  }
}
</style>
