<template>
  <div class="promotion-banner" :class="`promotion-banner--${columns}-columns`">
    <div
      v-for="(banner, index) in banners.filter((banner) => banner !== null)"
      :key="index"
      class="sf-card promotion-banner-item"
      :title="banner.bannerTitle"
      :subtitle="banner.bannerSubtitle"
      :description="banner.bannerDescription"
      :image="placeholderImage"
    >
      <BynderAsset :data="banner.asset" size="(max-width: 1023px) 100vw, (max-width: 1069px) 50vw, 610px" :ratio="1" />
      <div class="sf-card__details">
        <br-manage-content-button :content="banner.content" />
        <h2 v-if="banner.bannerTitle" class="sf-card__title" v-html="banner.bannerTitle" />
        <p class="sf-card__description" v-html="banner.bannerDescription" />
        <nuxt-link
          class="sf-button"
          :class="{
            'sf-button--invisible': !banner.ctaTitle,
            'sf-button--longtext': banner.ctaTitle && banner.ctaTitle.length > 20,
          }"
          :aria-disabled="false"
          :to="localePath(banner.ctaLink || banner.ctaUrl)"
        >
          {{ banner.ctaTitle }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  banners: any[]
}

const props = defineProps<Props>()
const localePath = useLocalePath()

const placeholderImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

const columns = computed(() => [4, 3, 2, 1].find((num) => (props.banners?.length || 1) % num === 0))
</script>

<style lang="scss">
.promotion-banner {
  --button-text-transform: none;
  --button-text-decoration: none;
  --button-text-transform: none;
  margin-bottom: var(--mj-component-margin-bottom);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--spacer-md);

  @media only screen and (max-width: 767px) {
    .mb-md & {
      margin-bottom: var(--margin-bottom-xxl);
    }
  }

  @include for-desktop {
    display: grid;
    gap: var(--spacer-md);
    grid-template-columns: repeat(2, 1fr);
  }

  .sf-card {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    overflow: hidden;

    &__image {
      display: none;
    }

    &__details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: var(--sand-background-color);
      flex: 1;

      @include for-desktop {
        padding: var(--spacer-md) var(--spacer-lg) var(--spacer-lg);
      }

      p {
        margin-top: 0;
        margin-bottom: var(--spacer-sm);
      }
    }

    &__title {
      --heading-title-font-size: var(--h2-font-size);
      --heading-title-font-weight: var(--font-weight--normal);
      padding: 0;
      margin-bottom: var(--spacer-xs);
    }

    &__description {
      color: var(--black-color);
      font-size: var(--font-size-sm);
      line-height: var(--global-line-height);

      &:empty {
        margin-bottom: var(--spacer-xs);
      }

      @include for-desktop {
        font-size: var(--global-font-size);
      }
    }

    .sf-button {
      --button-background: var(--black-color);
      --button-border-color: var(--black-color);
      --button-color: var(--white-color);
      --c-link-hover: var(--white-color);
      --button-height: var(--spacer-xl);
      margin-top: auto;
      position: static;
      min-height: var(--button-height);

      @media only screen and (max-width: 767px) {
        display: block;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: var(--spacer-10);
      }

      @include for-desktop {
        --button-height: unset;
      }

      &:hover,
      &:focus {
        --button-background: var(--black-color);
        --button-text-decoration: none;
      }
    }
  }

  &--2-columns {
    .sf-card__details {
      @media only screen and (max-width: 767px) {
        padding: var(--spacer-lg) var(--spacer-sm) var(--spacer-xl);
      }
    }
  }

  &--3-columns {
    grid-template-columns: repeat(2, 1fr);

    @include for-desktop {
      grid-template-columns: repeat(3, 1fr);
    }

    .sf-card__details {
      padding: var(--spacer-sm) var(--spacer-xs);

      @include for-desktop {
        padding: var(--spacer-md);
      }
    }
  }

  &--4-columns {
    grid-template-columns: repeat(2, 1fr);

    @include for-desktop {
      grid-template-columns: repeat(4, 1fr);
    }

    .sf-card__details {
      padding: var(--spacer-sm) var(--spacer-xs);

      @include for-desktop {
        padding: var(--spacer-md);
      }
    }

    .sf-card__description {
      @media only screen and (max-width: 767px) {
        font-size: var(--font-size--xs);
      }
    }

    .sf-card__title {
      font-size: var(--h3-font-size);

      @include for-desktop {
        font-size: 1.5rem;
      }
    }
  }
}

.promotion-banner-item {
  display: flex;
  flex-direction: column;
}
</style>
