<template>
  <ul
    v-if="items.length"
    class="top-nav__submenu"
    ref="submenu"
    :class="menuClassName"
    :aria-expanded="isExpanded"
    :style="{ minHeight: minHeight, marginLeft: marginLeft }"
    @mouseleave="onBlur"
  >
    <li
      class="nav-item nav-item__title"
      :class="{ 'nav-item__back-link': showBackLink }"
      v-if="title"
      :role="showBackLink ? 'button' : null"
      @click="collapse"
    >
      <ChevronLeftIcon v-if="showBackLink" />
      <span v-html="formatLink(title).name"></span>
    </li>

    <TopNavMenuItem
      v-for="(item, index) in items"
      :key="index"
      :index="`${menuId}_${index + 1}`"
      :item="item"
      :level="level"
      @setMinHeight="setMinHeight"
      @opened="openened"
      @closed="isExpanded = false"
    />
  </ul>
</template>

<script setup>
import ChevronLeftIcon from "~/assets/icons/chevron-left-alt.svg"

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  level: {
    type: Number,
    required: true,
  },
  menuId: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(["setMinHeight", "collapse"])
const { isDesktop } = useDevice()
const marginLeft = ref(null)
const minHeight = ref("100%")

const isExpanded = ref(false)
const submenu = ref()

const showBackLink = computed(() => props.level > (isDesktop ? 2 : 1))

const menuClassName = computed(() => {
  return [`top-nav__submenu--level${props.level}`, props.isOpen && "top-nav__submenu--expanded"]
})

const setMinHeight = (height) => {
  minHeight.value = height
}

const onResize = () => {
  marginLeft.value = null

  if (isDesktop) {
    const parentEl = submenu.value.parentElement
    const boundingRect = parentEl.getBoundingClientRect()
    marginLeft.value =
      boundingRect.left + boundingRect.width / 2 < 250
        ? 504 / 2 - boundingRect.left + boundingRect.width / 2 - boundingRect.width + "px"
        : null
  }
}

const formatLink = (name) => {
  // name + label e.g.: "rokjes {deal}"
  return {
    name: name.replace(/ *\{[^)]*\} */g, ""),
    label: name.match(/{(\w+)}/, "$1")?.[1],
  }
}

const collapse = (event) => {
  event.preventDefault()
  emit("collapse", true)
  emit("setMinHeight", "100%")
}

const onBlur = () => {
  setMinHeight("100%")
  emit("collapse", true)
  emit("setMinHeight", "100%")
}

const openened = () => {
  isExpanded.value = true
  submenu.value.scrollTop = 0
}

onMounted(() => {
  onResize()
  window.addEventListener("resize", onResize)
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", onResize)
})
</script>
