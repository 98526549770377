<template>
  <!-- <LazyHydrate v-if="video" when-visible> -->
  <div :class="isPreview ? 'has-edit-button' : ''">
    <br-manage-content-button :content="document" />
    <div class="video-player">
      <h2 v-if="title" v-html="title" />
      <div class="video-player__iframe-wrapper">
        <iframe
          type="text/html"
          width="640"
          height="360"
          frameborder="0"
          allowfullscreen
          :src="videoSource"
          :allow="video.autoplay ? 'autoplay' : ''"
        ></iframe>
      </div>
    </div>
  </div>
  <!-- </LazyHydrate> -->
</template>

<script setup>
const props = defineProps({
  component: null,
  videoContent: null,
  page: null,
})

const isPreview = computed(() => props.page?.isPreview())
const title = computed(() => props.component?.getProperties()?.title)

const document = computed(() => {
  if (props.videoContent) {
    return props.videoContent
  }

  const { document } = props.component.getModels()
  return props.page.getContent(document)
})

const video = computed(() => {
  const data = document?.value?.getData()

  if (!data) {
    return {}
  }

  return {
    ...data,
  }
})

const color = computed(() => video.value.color?.selectionValues[0]?.key)

const parseVideoUrl = (url) => {
  const match = url.match(/(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i)
  return match ? match[1] : undefined
}

const videoSource = computed(() => {
  const { videoUrls, fullScreenPossible, autoplay } = video.value
  if (videoUrls?.length) {
    const videoIDs = videoUrls?.map((url) => parseVideoUrl(url))
    return (
      'https://www.youtube.com/embed/' +
      videoIDs[0] +
      '?playlist=' +
      videoIDs.join(',') +
      '&autoplay=' +
      (autoplay ? 1 : 0) +
      '&fs=' +
      (fullScreenPossible ? 1 : 0) +
      '&color=' +
      color.value +
      '&mute=' +
      (autoplay ? 1 : 0) +
      '&rel=0&loop=0&modestbranding=1'
    )
  }
  return undefined
})
</script>

<style lang="scss">
.video-player {
  text-align: center;
  margin-top: var(--spacer-xs);
  margin-bottom: var(--mj-component-margin-bottom);

  &__iframe-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 768px;

    &:before {
      content: '';
      display: block;
      padding-top: 56.25%; // = aspect ratio 16/9
    }
  }

  h2 {
    line-height: var(--sm-line-height);
    margin-bottom: var(--spacer-md);
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
