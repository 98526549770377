<template>
  <div class="footer-middle grid-container">
    <FooterMiddleColumn id="footer-middle-need-help" show-on="all">
      <template #default="{ toggleExpanded }">
        <br-component component="footer1-menu">
          <template #default="{ component, page }">
            <FooterMenu :component="component" :page="page" @toggleExpanded="toggleExpanded" />
          </template>
        </br-component>
      </template>
    </FooterMiddleColumn>

    <FooterMiddleColumn id="footer-middle-about-my-jewellery" show-on="all">
      <template #default="{ toggleExpanded }">
        <br-component component="footer2-menu">
          <template #default="{ component, page }">
            <FooterMenu :component="component" :page="page" @toggleExpanded="toggleExpanded" />
          </template>
        </br-component>
      </template>
    </FooterMiddleColumn>

    <FooterMiddleColumn id="footer-middle-socials" show-on="desktop">
      <template #default="{ toggleExpanded }">
        <br-component component="footer3-menu">
          <template #default="{ component, page }">
            <FooterMenu :component="component" :page="page" @toggleExpanded="toggleExpanded" />
          </template>
        </br-component>
      </template>
    </FooterMiddleColumn>

    <FooterMiddleColumn id="footer-middle-payment-providers" show-on="mobile">
      <template #default="{ toggleExpanded }">
        <h4 @click="toggleExpanded">{{ $t('Delivery & Payment') }}</h4>
        <FooterPaymentProviders />
      </template>
    </FooterMiddleColumn>

    <div id="footer-middle-conditions-privacy-reviews">
      <br-component component="footer-legal-menu">
        <template #default="{ component, page }">
          <FooterMenu :component="component" :page="page" />
        </template>
      </br-component>
    </div>
  </div>
</template>

<style lang="scss">
.footer-middle {
  padding: var(--spacer-xl) 0;
  background: var(--black-color);
  color: var(--white-color);

  @include for-mobile {
    padding: 0;

    h4 {
      position: relative;
      margin: var(--spacer-md) 0 0;

      &:after {
        content: '';
        position: absolute;
        background-image: url('data:image/svg+xml;utf8,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 13.9545L10.1202 13L16 18.0779L21.8798 13L23 13.9545L15.9999 20L9 13.9545Z" fill="white"/></svg>');
        width: 32px;
        height: 32px;
        right: 0;
        top: -4px;
        pointer-events: none;
        transition: transform 0.25s ease-in-out;
      }
    }

    .footer-middle-column:not(.footer-middle-column--open) h4 ~ * {
      display: none;
    }

    .footer-middle-column--open h4:after {
      transform: rotate(180deg);
    }

    ul li ul,
    ul:not(:has(ul)) {
      padding: var(--spacer-sm) 0;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-sm);
  }

  svg,
  span {
    fill: var(--white-color);
    color: var(--white-color);
  }

  svg {
    margin-right: var(--spacer-xs);
  }

  a {
    display: flex;
    align-items: center;
    color: var(--white-color);

    @include for-mobile {
      font-size: var(--font-size-sm);
    }
  }

  #footer-middle-conditions-privacy-reviews {
    border: none;
    font-size: var(--font-size-sm);
    text-decoration: underline;

    @include for-desktop {
      display: none;
    }

    ul {
      flex-direction: row;
    }
  }
}
</style>
