<template>
  <div>
    <SfButton
      class="container__lang container__lang--selected sf-button--pure"
      @click="toggleLocaleSidebar"
      data-testid="languageSwitch"
    >
      <SfCharacteristic class="store-switcher">
        <template #title>
          <span>{{ locale.substring(0, 2).toUpperCase() }}</span>
          <ChevronDownIcon />
        </template>
        <template #icon v-if="locale">
          <div class="language__flag">
            <component
              :is="flagIcon"
              alt="Flag"
              width="26"
              height="26"
              class="language__flag-icon"
              :class="'language__flag-icon--' + locale.toLowerCase()"
            />
          </div>
        </template>
      </SfCharacteristic>
    </SfButton>
  </div>
</template>

<script setup>
import ChevronDownIcon from '~/assets/icons/chevron-down.svg'
import deFlagIcon from '~/assets/icons/flags/de.svg'
import enFlagIcon from '~/assets/icons/flags/en.svg'
import frFlagIcon from '~/assets/icons/flags/fr.svg'
import nlFlagIcon from '~/assets/icons/flags/nl.svg'

const { toggleLocaleSidebar } = useUiState()
const { locale } = useI18n()

const flagIcon = computed(() => {
  const localeString = locale.value.substring(0, 2)
  switch (localeString) {
    case 'de':
      return deFlagIcon
    case 'en':
      return enFlagIcon
    case 'fr':
      return frFlagIcon
    case 'nl':
      return nlFlagIcon
    default:
      return enFlagIcon
  }
})
</script>

<style lang="scss">
.container__lang {
  --button-box-shadow: none;
  background: none;
  padding: 0;
  display: flex;
  align-items: center;
  opacity: 0.5;
  border: none;

  &:hover,
  &--selected {
    opacity: 1;
  }

  &:hover,
  &:focus {
    .store-switcher {
      color: var(--primary-color);
    }
  }
}

.store-switcher {
  text-transform: capitalize;
  color: var(--black-color);
  font-size: var(--font-size-13);
  font-weight: var(--font-weight--normal);
  transition: color 0.25s ease;

  .language {
    &__flag {
      margin-right: var(--spacer-xs);
      border-radius: 50%;
      overflow: hidden;
      width: 26px;
      height: 26px;

      &-icon {
        &--en {
          background-color: #039;
        }
      }
    }
  }

  .sf-characteristic__description {
    display: none;
  }

  .sf-characteristic__text {
    display: flex;
    gap: 7px;
    align-items: center;
  }
}
</style>
