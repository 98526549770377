<template>
  <div v-if="component" class="seo-text" :class="{ 'has-edit-button': isPreview }">
    <div class="grid-container" :style="`--column-count: ${seoText.fullWidth ? 1 : 2}`">
      <br-manage-content-button :content="content" />
      <div class="two-column-text" v-html="seoText?.part1?.value" />
      <div
        class="two-column-text"
        :class="{
          'is-invisible':
            isInvisible && ((seoText.hideSecondPartMobile && isMobile) || (seoText.hideSecondPartDesktop && !isMobile)),
        }"
        v-html="seoText?.part2?.value"
      />
      <div class="two-column-text" :class="{ 'is-invisible': isInvisible }" v-html="seoText?.part3?.value" />
      <div class="two-column-text" :class="{ 'is-invisible': isInvisible }" v-html="seoText?.part4?.value" />
      <BloomreachFaq
        v-if="seoText.faq?.$ref"
        class="two-column-text"
        :class="{ 'is-invisible': isInvisible }"
        :faqRef="seoText.faq?.$ref"
      />
      <button
        class="link sf-button sf-button--text"
        :class="!showButton ? 'seo-text__hidden' : ''"
        @click="isInvisible = !isInvisible"
      >
        {{ isInvisible ? $t('Read more') : $t('Read less') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  component: null,
  page: null,
})

const device = useDevice()
const isMobile = ref(!device.isDesktop)
const isInvisible = ref(true)
const { bloomreachPage } = storeToRefs(usePageStore())

const seoText = computed(
  () =>
    Object.values(bloomreachPage.value?.page || {}).find((item) => item.data?.contentType === 'brxsaas:mjSEO')?.data ||
    {},
)

const document = computed(
  () => props.page?.document?.$ref?.replace('/page/', '') || props.component?.getModels()?.document,
)
const content = computed(() => props.page?.page?.[document.value] || props.page?.getContent(document.value))
const isPreview = computed(() => (props.page?.isPreview ? props.page.isPreview() : false))

const showButton = computed(
  () =>
    (seoText.value?.part1?.value && seoText.value?.part3?.value) ||
    (seoText.value?.part1?.value &&
      seoText.value?.part2?.value &&
      isMobile.value &&
      seoText.value.hideSecondPartMobile.value) ||
    (seoText.value?.part1?.value &&
      seoText.value?.part2?.value &&
      !isMobile.value &&
      seoText.value.hideSecondPartDesktop.value),
)
</script>

<style lang="scss">
.hst-container-item:has(.seo-text) {
  grid-column: 1 / -1;
}

.seo-text {
  position: relative;
  margin: 0;
  padding: var(--spacer-lg) 0;
  background-color: var(--gray-background-color);

  @include for-desktop {
    padding: var(--spacer-lg) 0;
    width: 100%;
  }

  a,
  .link {
    display: inline-block;
    text-underline-offset: 2px;
    text-decoration: underline;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      color: var(--cta-pink-color);
    }
  }

  .link {
    margin: 12px 0 var(--spacer-md);
    appearance: none;
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
  }

  p {
    @include for-desktop {
      column-count: var(--column-count);
      column-gap: 20px;
    }
  }

  &__hidden {
    display: none !important;
  }
}

.two-column-text {
  &.is-invisible {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
}
</style>
