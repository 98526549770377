<template>
  <div v-if="component" class="shop-in-shop" :class="{ 'has-edit-button': isPreview }">
    <header v-if="title" class="shop-in-shop__header">
      <h2 class="shop-in-shop__title" v-html="title" />
      <HTMLContent v-if="content" class="shop-in-shop__description" :content="content" />
    </header>

    <div class="shop-in-shop__items">
      <a
        v-for="(shop, index) in limitedShops"
        :key="index"
        class="shop-in-shop__item"
        :href="shop.storeUrl"
        target="_blank"
      >
        <div class="shop-in-shop__card-title">{{ shop.storeName }}</div>
        <div class="shop-in-shop__card-city">
          {{ shop.city }}
          <ChevronRightIcon />
        </div>
        <div class="shop-in-shop__card-address">
          <div>{{ shop.streetName }} {{ shop.houseNumber }}</div>
          <div>{{ shop.postalCode }} {{ shop.city }}</div>
          <div>{{ shop.country }}</div>
        </div>
      </a>
    </div>
    <div class="shop-in-shop__button-container" :class="!showButton ? 'shop-in-shop__hidden' : ''">
      <div class="shop-in-shop__button">
        <button
          class="sf-button sf-button--pure"
          :aria-disabled="false"
          :link="null"
          type="button"
          aria-label="button"
          @click="toggleButton"
        >
          {{ $t(buttonLabel) }}<ChevronRightIcon />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Document, Pagination as BrPagination, Page, Component } from '@bloomreach/spa-sdk'
import ChevronRightIcon from '~/assets/icons/chevron-right-alt.svg'

interface BrProps {
  page: Page
  component: Component
}

export interface ShopInShopDocument {
  storeName: string
  streetName: string
  houseNumber: string
  addition: string
  postalCode: string
  city: string
  country: string
  storeUrl: string
}

const props = defineProps<BrProps>()

const device = useDevice()
let limitedShopsNumber = ref(6)
const limitStores = ref(true)

const toggleButton = () => {
  limitStores.value = !limitStores.value
}

onMounted(() => {
  if (device.isDesktop) {
    limitedShopsNumber.value = 12
  }
})

const { page, component } = <BrProps>props
const showButton = computed(() => limitedShopsNumber.value < shops.length)
const limitedShops = computed(() => (limitStores.value ? shops.slice(0, limitedShopsNumber.value) : shops))
const buttonLabel = computed(() => (limitStores.value ? 'Show more shop-in-shops' : 'Show less shop-in-shops'))
const title = computed(() => component?.getProperties()?.title)
const content = computed(() => component?.getProperties()?.description)
const isPreview = page.isPreview()
const { pagination: paginationRef } = component.getModels()
const pagination = paginationRef && page.getContent<BrPagination>(paginationRef)

const documents = pagination
  .getItems()
  .map((ref) => page.getContent<Document>(ref))
  .filter((doc: Document) => !doc.getData<ShopInShopDocument>().excludeFromFeed)

let shops: ShopInShopDocument[] = documents.map((doc: Document) => {
  return doc.getData<ShopInShopDocument>()
})

shops = shops.sort((shop1, shop2) =>
  shop1.country > shop2.country || (shop1.country == shop2.country && shop1.city > shop2.city) ? 1 : -1,
)
</script>

<style lang="scss">
.shop-in-shop {
  --shop-in-shop-gap: var(--spacer-xs);
  --shop-in-shop-columns: 1;

  position: relative;
  max-width: var(--container-width-lg);
  margin-bottom: var(--mj-component-margin-bottom);

  @include for-desktop {
    --shop-in-shop-gap: var(--spacer-md);
    --shop-in-shop-columns: 4;
  }

  &__header {
    display: grid;
    place-items: center;
  }

  &__title {
    font-size: var(--h1-font-size);
    font-family: var(--font-family--secondary);
    line-height: var(--sm-line-height);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__description {
    padding-bottom: var(--shop-in-shop-gap);
    padding-left: 24px;
    padding-right: 24px;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(var(--shop-in-shop-columns), 1fr);
    gap: var(--shop-in-shop-gap);
  }

  &__item {
    font-size: var(--global-font-size);
    padding: var(--spacer-xs) var(--spacer-sm);

    @include for-desktop {
      padding: var(--spacer-sm) var(--spacer-base);
    }

    &:hover {
      background: var(--gray-background-color);
    }
  }

  &__button-container {
    display: grid;
    place-items: center;
  }

  &__button {
    display: inline-block;
    font-size: var(global-font-size);
    font-family: var(--font-family--primary);
    font-weight: var(--font-weight-default);
    padding: 16px 24px;
    text-decoration: underline;
    &:hover {
      background: var(--gray-background-color);
    }
  }

  &__card-title {
    font-size: var(--font-size-10);
    font-family: var(--font-family--primary);
    line-height: var(--spacer-18);
    color: var(--cta-pink-color);

    @include for-desktop {
      font-size: var(--font-size-13);
    }
  }

  &__card-city {
    font-size: var(--h3-font-size);
    font-family: var(--font-family--primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    border-bottom: 1px solid #e0e0e1;
    padding-bottom: var(--spacer-10);
    margin-bottom: var(--spacer-10);
    line-height: var(--spacer-18);

    @include for-desktop {
      line-height: var(--global-line-height);
    }
  }

  &__card-address {
    @include for-mobile {
      font-size: var(--font-size-xs);
      line-height: var(--spacer-18);

      > div {
        display: inline;

        &:after {
          content: ', ';
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  &__hidden {
    display: none;
  }
}
</style>
