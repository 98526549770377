<template>
  <SfLoader :loading="loading">
    <div class="top-nav__ctas">
      <ul>
        <li v-for="item in items" :key="item.id">
          <SfLink :link="locale + item.headerLink" @click.native="closeMobileMenu">
            <div v-dompurify-html:svg="item.icon" />
            <span>{{ item.title }}</span>
          </SfLink>
        </li>
      </ul>
      <hr v-if="items && items.length" />
    </div>
  </SfLoader>
</template>

<script setup lang="ts">
import { useUiHelpers } from "~/composables"
const { locale } = useI18n()

defineProps({
  loading: { type: Boolean, default: false },
  items: { type: Array, default: [] },
})

const { toggleMobileMenu } = useUiState()
const { toggleMobileMenuOpenClassOnBody } = useUiHelpers()

const closeMobileMenu = () => {
  toggleMobileMenu()
  toggleMobileMenuOpenClassOnBody()
}
</script>

<style lang="scss">
.top-nav__ctas {
  position: relative;

  @include for-desktop {
    display: none;
  }

  hr {
    border-width: 1px 0 0 0;
    border-color: var(--black-color);
    margin: var(--spacer-xs) 0 var(--spacer-lg);
  }

  ul {
    li {
      .sf-link {
        --link-font-family: var(--font-family--primary);
        --link-font-weight: var(--font-weight--semibold);
        --link-text-decoration: none;
        display: flex;
        flex-flow: nowrap;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: var(--spacer-12) var(--spacer-sm);
        line-height: var(--spacer-sm);

        svg {
          display: block;
          margin-right: var(--spacer-sm);
        }

        span {
          flex: 1;
          line-height: var(--spacer-md);
        }
      }
    }
  }
}
</style>
