<template>
  <header class="top-header" :class="{ 'top-header--sticky': isSticky }" :style="`padding-top: ${paddingTop}px`">
    <div
      class="top-header__content"
      :class="{ 'is-visible': isTopHeaderVisible }"
      :style="!isMobile && isTopHeaderVisible ? null : `margin-top: -${invisibleScrollOffset}px`"
      ref="topHeaderContent"
    >
      <TopBar />
      <AppHeader />
      <TopNav>
        <br-component component="menu" />
      </TopNav>
      <br-component component="header" />
    </div>
  </header>
</template>

<script setup lang="ts">
const uiState = useUiState()
const { isTopHeaderVisible } = storeToRefs(uiState)
const { toggleTopHeader } = uiState
const lastScrollY = ref(0)
const lastOnScrollChange = ref(0)
const lastVisibleScrollY = ref(0)
const invisibleScrollOffset = ref(0)
const ticking = ref(false)
const scrollMargin = 90
const paddingTop = ref(0)
const topHeaderContent = ref()
const isMobile = ref(true)
const isSticky = ref(false)

const determineVisibility = () => {
  let isVisible = true
  const scrollY = window.scrollY

  // Always show on top of page
  if (scrollY < 50) {
    return toggleTopHeader(true)
  }

  const scrollDirection = lastScrollY.value < scrollY ? 'down' : 'up'

  // Hide after amount scrolled
  if (scrollY > scrollMargin) {
    isVisible = false
  }

  if (scrollDirection === 'up') {
    // Show after amount scrolled up
    if (lastOnScrollChange.value - scrollY > scrollMargin) {
      isVisible = true
    }
  } else {
    lastOnScrollChange.value = scrollY
  }

  lastScrollY.value = scrollY
  if (!isMobile.value) invisibleScrollOffset.value = scrollY - lastVisibleScrollY.value
  else invisibleScrollOffset.value = 0

  if (isVisible !== isTopHeaderVisible.value) {
    return toggleTopHeader(isVisible)
  }
}

const update = () => {
  ticking.value = false
  determineVisibility()
}

const onScroll = () => {
  requestTick()
}

const requestTick = () => {
  if (!ticking.value) {
    requestAnimationFrame(update)
  }
  ticking.value = true
}

// Ensure proper height on resize screen or adding/removing elements (like a notification)
const resizeObserver = () => {
  const observer = new ResizeObserver(() => {
    if (isSticky.value) paddingTop.value = topHeaderContent.value?.offsetHeight
    isMobile.value = window.outerWidth < 1024
  })
  observer.observe(topHeaderContent.value)
}

onMounted(() => {
  isSticky.value = true
  window.addEventListener('scroll', onScroll, false)
  window.addEventListener('touchmove', onScroll, false)
  if (isSticky.value) paddingTop.value = topHeaderContent.value?.offsetHeight
  isMobile.value = window.outerWidth < 1024

  resizeObserver()
})

watch(isTopHeaderVisible, () => {
  if (!isMobile.value && isTopHeaderVisible.value === false) lastVisibleScrollY.value = window.scrollY
})
</script>

<style lang="scss" scoped>
.top-header {
  position: relative;
  width: 100%;
  z-index: 4;

  &__content {
    position: relative;
    z-index: 2;
    top: 0;
    left: 0;
    width: inherit;
    transition: transform 0.3s ease-out;

    &:not(.is-visible) {
      transform: translateY(-100%);
    }
  }

  &--sticky {
    padding-top: 118px;

    @media (min-width: 1024px) {
      padding-top: 191px;
    }

    .top-header__content {
      position: fixed;
    }
  }
}
</style>
