<template>
  <ProductCardLoading type="default" v-if="loading" />
  <ProductCard v-else-if="product.sku" :product="product" :showAtcButton="showAtcButton" />
</template>
<script lang="ts" setup>
import useProduct from '~/composables/useProduct'
import type { Component, Page } from '@bloomreach/spa-sdk'

interface Props {
  component: Component
  page: Page
}

const props = defineProps<Props>()

const { getProductList } = useProduct()

const { sku } = props.component.getParameters()
const loading = ref(true)

let product = ref({})

onMounted(async () => {
  const result = await getProductList({
    filter: {
      sku: {
        in: [sku],
      },
    },
  })

  product.value = result.items?.[0] || {}
  loading.value = false
})

const showAtcButton = ref(true)
</script>

<style scoped lang="scss"></style>
