<template>
  <div class="container">
    <section class="piercing-store-grid">
      <BloomreachPiercingStoresGridItem v-for="(store, index) in piercingStores" :key="index" :store="store" />
    </section>
  </div>
</template>

<script setup lang="ts">
import type { Document, Pagination as BrPagination, Page, Component } from '@bloomreach/spa-sdk'
import type { Store, StoreDocument, StoreAddress } from '~/types/storeGrid'
import getBynderImageUrl from '~/utils/bynderImage'

const props = defineProps({
  component: null,
  page: null,
})

const { pagination: paginationRef } = props.component.getModels()
const pagination = paginationRef && props.page.getContent<BrPagination>(paginationRef)
const documents = pagination?.getItems().map((ref) => props.page.getContent<Document>(ref)) || []
const maxSize = props.component?.model?.meta?.paramsInfo?.customSize ?? 1000

const stores: Store[] = documents
  ?.map((doc) => {
    const {
      storeName,
      storeImage,
      displayName,
      streetName,
      houseNumber,
      addition,
      postalCode,
      city,
      country,
      bookingUrl,
      bookingImage,
    } = doc.getData<StoreDocument>()

    return <Store>{
      name: displayName,
      address: <StoreAddress>{
        streetName,
        houseNumber,
        addition,
        postalCode,
        city,
        country,
      },
      storeName,
      storeImage: getBynderImageUrl(storeImage),
      storeUrl: doc.getUrl(),
      bookingUrl: props.page.getContent(bookingUrl)?.getUrl(),
      bookingImage: getBynderImageUrl(bookingImage),
      document: doc,
    }
  })
  .filter((s) => s.bookingImage)
  .slice(0, maxSize)

const cities = stores
  .map((store) => {
    return store.address.city
  })
  .reduce((acc, curr) => {
    return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc
  }, {})

const piercingStores = stores.map((store) => ({
  ...store,
  displayStreetName: cities?.[store.address.city] > 1 || false, // allStores.find((s) => s.address.city === s),
}))
</script>

<style lang="scss">
.piercing-store-grid {
  display: grid;
  column-gap: var(--spacer-xs);
  row-gap: var(--spacer-xs);
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  margin-bottom: var(--spacer-xs);

  @include for-desktop {
    display: grid;
    column-gap: var(--spacer-md);
    row-gap: var(--spacer-lg);
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: var(--mj-component-margin-bottom);
    width: calc(100% - (var(--spacer-md) * 2));
  }
}
</style>
