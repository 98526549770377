<template>
  <div class="sf-color-picker" :class="{ 'sf-color-picker--active': isOpen }">
    <transition name="sf-color-picker" mode="out-in">
      <div v-if="!isOpen" key="color-picker-button" class="sf-color-picker__button smartphone-only">
        <slot name="open" v-bind="{ toggle }">
          <SfButton class="color-secondary sf-color-picker__button-open" @click="toggle">+ Colors</SfButton>
        </slot>
      </div>
      <div v-else key="color-picker-colors" class="sf-color-picker__colors">
        <SfOverlay :visible="isOpen" class="sf-color-picker__colors__overlay smartphone-only" />
        <slot name="label" v-bind="{ label }">
          <div :class="{ 'display-none': !label }" class="sf-color-picker__label smartphone-only">
            {{ label }}
          </div>
        </slot>
        <slot />
        <slot name="close" v-bind="{ hasClose, toggle, isOpen }">
          <SfButton
            :class="{ 'display-none': !hasClose }"
            class="sf-button--text sf-color-picker__close smartphone-only"
            aria-label="Close button"
            :aria-pressed="!isOpen"
            @click="toggle"
          >
            <SfIcon icon="cross" />
          </SfButton>
        </slot>
      </div>
    </transition>
  </div>
</template>
<script setup>
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  hasClose: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['click:toggle'])

const toggle = () => emit('click:toggle')
</script>
<style lang="scss">
@import './styles/molecules/SfColorPicker.scss';
</style>
