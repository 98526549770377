<template>
  <div class="sf-search-bar">
    <input
      v-focus
      class="sf-search-bar__input"
      type="search"
      :value="value"
      :placeholder="placeholder"
      v-bind="$attrs.attrs"
    />
    <slot name="icon">
      <SfButton
        class="sf-search-bar__button sf-button--pure"
        type="button"
        aria-label="Search"
        @click="$emit('click', value)"
      >
        <span v-if="icon" class="sf-search-bar__icon">
          <SfIcon :color="icon.color" :size="icon.size" icon="search" />
        </span>
      </SfButton>
    </slot>
  </div>
</template>

<script>
import { focus } from './utilities/directives'

export default defineComponent({
  name: 'SfSearchBar',
  directives: {
    focus,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: null,
    },
    icon: {
      type: Object,
      default: () => ({}),
    },
  },
})
</script>
<style lang="scss">
@import './styles/molecules/SfSearchBar.scss';
</style>
