<template>
  <div class="recommended-articles" :style="styles" :data-bg-color="bgColor">
    <div class="container">
      <header class="recommended-articles__header">
        <h2 v-if="params.title" v-html="params.title" />
      </header>
      <div v-if="banners.length" ref="grid" class="recommended-articles__grid">
        <CardItem
          v-for="(banner, index) in banners.filter((filter) => filter !== null)"
          :key="index"
          ref="card"
          :title="banner.bannerTitle"
          :description="banner.bannerDescription"
          :category="getCategory(banner)"
          :image="banner.bannerImage"
          :asset="banner.asset"
          :link="localePath(banner.ctaUrl || banner.ctaLink)"
        />
        <div v-if="params.ctaUrl" class="sf-card recommended-articles__grid-more-card">
          <a class="recommended-articles__grid-more-card__link" :href="params.ctaUrl">
            {{ params.ctaTitle || $t('See more') }}
          </a>
        </div>
      </div>
      <nav v-if="banners.length" class="recommended-articles__nav">
        <span
          v-for="(banner, index) in banners.filter((filter) => filter !== null)"
          :key="index"
          ref="sliderIcon"
          :class="{ active: currentIndex === index }"
          role="button"
          :aria-label="`Slide to ${banner.bannerTitle}`"
          @click="slideTo(index)"
        ></span>
      </nav>
      <footer v-if="params.ctaUrl" class="recommended-articles__footer">
        <a :class="['recommended-articles__footer-link', params.ctaTitle || 'empty']" :href="params.ctaUrl">
          {{ params.ctaTitle || $t('See more') }}
        </a>
      </footer>
    </div>
  </div>
</template>

<script setup lang="ts">
import CardItem from '~/components/Bloomreach/Banner/CardItem.vue'

interface Props {
  banners: any[]
  params: Record<string, any>
  bannerType?: string
}

const props = defineProps<Props>()

const card = ref()
const grid = ref()
const currentIndex = ref(0)
const intersectionObserver = ref()
const localePath = useLocalePath()

const bgColor = computed(() => props.bannerType?.match(/gray|pink|sand|white/) || 'white')

const styles = computed(() => ({
  backgroundColor: `var(--${bgColor.value}-background-color)`,
}))

const slideTo = (index) => {
  const left = card.value[index].$el.offsetLeft
  grid.value.scrollTo({
    top: 0,
    left,
    behavior: 'smooth',
  })
  currentIndex.value = index
}

const getCategory = (banner) => {
  return banner?.hideCategory ? '' : banner.categoryManually ? banner.categoryManually : banner.category || ''
}

onMounted(() => {
  intersectionObserver.value = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        const index = [...entry.target.parentElement.children].indexOf(entry.target)
        currentIndex.value = index
      }
    },
    {
      threshold: [0.25, 1],
    },
  )

  card.value.forEach((card) => {
    intersectionObserver.value.observe(card.$el)
  })
})

onBeforeUnmount(() => {
  intersectionObserver.value?.disconnect()
})
</script>

<style lang="scss">
.recommended-articles {
  position: relative;
  padding: var(--spacer-base) 0;
  width: 100%;
  margin-bottom: 34px;

  &__header,
  &__footer {
    text-align: center;
  }

  &__header {
    h2 {
      font-weight: var(--font-weight--normal);
      line-height: var(--sm-line-height);
      margin-top: var(--spacer-md);
      margin-bottom: var(--spacer-xs);

      @include for-desktop {
        margin-bottom: var(--spacer-md);
      }
    }
  }

  &__footer {
    padding-top: var(--spacer-md);

    @media only screen and (max-width: 767px) {
      display: none !important;
    }

    &-link {
      text-decoration: underline;
      font-size: var(--font-size-xs);
      color: var(--black-color);

      &:hover,
      &:focus {
        color: var(--black-secondary-color);
      }

      @include for-desktop {
        font-size: var(--font-size--lg);
        font-weight: var(--font-weight--semibold);
      }
    }
  }

  &__grid {
    display: flex;
    flex-wrap: nowrap;
    gap: var(--spacer-xs);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }

    @include for-desktop {
      overflow: hidden;
      flex-wrap: wrap;
      gap: var(--spacer-md);
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacer-sm) var(--spacer-sm) var(--spacer-xs);

    @include for-desktop {
      display: none;
    }

    span {
      display: block;
      width: var(--spacer-xs);
      height: var(--spacer-xs);
      margin: 0 var(--spacer-2xs);
      border-radius: 50%;
      background-color: var(--gray-primary-color);
      transition: all 0.2s ease;

      &.active {
        background-color: var(--secondary-color);
        transform: scale(1.25);
      }
    }
  }

  &__grid-more-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white-color);
    padding: var(--spacer-xs);
    border-radius: var(--spacer-2xs);

    @include for-desktop {
      display: none !important;
    }

    a {
      font-size: var(--font-size-10);
      line-height: var(--spacer-md);
      text-decoration: underline;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .sf-card {
    flex: 0 0 88%;
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: var(--spacer-2xs);
    overflow: hidden;
    scroll-snap-align: center;
    scroll-margin-left: var(--spacer-15);
    scroll-margin-right: var(--spacer-15);

    @media only screen and (max-width: 767px) {
      &:first-child {
        margin-left: var(--spacer-15);
      }

      &:last-child {
        margin-right: var(--spacer-15);
      }
    }

    @include for-desktop {
      flex: 1;
      min-width: 295px;
    }

    .sf-card__image {
      position: relative;
      --card-image-relative-height: 77.58%;

      @include for-desktop {
        --card-image-relative-height: 100%;
      }

      .sf-image {
        --image-height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: var(--spacer-2xs);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    @media only screen and (max-width: 767px) {
      .sf-card__category {
        font-size: var(--font-size-13);
      }

      .sf-card__title {
        font-size: var(--font-size-18);
      }

      .sf-card__description {
        font-size: var(--font-size-sm);
        line-height: 1.375;
      }
    }

    .sf-card__link {
      font-size: 0;
      line-height: 0;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .sf-card__details {
      background-color: var(--white-color);
    }
  }

  &[data-bg-color='white'] {
    padding-top: 0;

    .recommended-articles__grid-more-card,
    .sf-card .sf-card__details {
      background-color: var(--gray-background-color);
    }
  }
}
</style>
