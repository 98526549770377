<template>
  <ul v-if="menu" class="top-nav__menu" :class="{ 'has-edit-button': page.isPreview(), 'is-expanded': isExpanded }">
    <br-manage-menu-button :menu="menu" />
    <header class="top-nav__header mobile-only">
      <span class="top-nav__header-title">Menu</span>
      <StoreSwitcher class="store-switcher mobile-only" />
    </header>
    <TopNavMenuItem
      v-for="(item, index) in menu.getItems()"
      :key="index"
      :index="index + 1"
      :item="item"
      :level="1"
      @opened="isExpanded = true"
      @closed="isExpanded = false"
    />
    <TopNavCtaItems class="mobile-only" :loading="ctaUspsLoading" :items="ctaItems" @itemClicked="toggleMobileMenu" />
    <TopNavUspItems class="mobile-only" :loading="ctaUspsLoading" :items="uspItems" />
  </ul>
</template>

<script lang="ts" setup>
import type { Component as BrComponent, Page } from '@bloomreach/spa-sdk'
import { useMenu } from '~/composables'

const logger = useAppLogger("TopNavMenu")

const props = defineProps({
  component: {
    type: Object as () => BrComponent,
    required: true,
  },
  page: {
    type: Object as () => Page,
    required: true,
  },
})

const uiState = useUiState()
const { toggleMobileMenu } = uiState
const { isMobileMenuOpen } = storeToRefs(uiState)
const { menu } = useMenu(props)

const { isDesktop } = useDevice()
const ctaItems = ref([])
const uspItems = ref([])
const ctaUspsLoading = ref(false)
const ctaUspsLoaded = ref(false)
const isExpanded = ref(false)

const { $brxmEndpointResolver } = useNuxtApp()

const fetchDocumentData = async (ref) => {
  const endpoint = $brxmEndpointResolver.resolveDocumentsEndPoint(ref)

  const data = await $fetch(endpoint)

  const headerLink =
    data?.content?.[data?.content[data?.document?.$ref.substring(9)].data?.headerLink?.$ref?.substring(9)]?.links?.site
      ?.href || null
  return { ...data?.content[data?.document?.$ref.substring(9)].data, headerLink } || {}
}

const loadMobileHeaderItems = async () => {
  ctaUspsLoading.value = true
  try {
    const items = await fetchDocumentData('/header/mobileheaderctausps')
    ctaItems.value = await Promise.all(
      items?.header1?.map((item) => item.$ref.substring(9)).map((ref) => fetchDocumentData(ref)),
    )
    uspItems.value = await Promise.all(
      items?.header2?.map((item) => item.$ref.substring(9)).map((ref) => fetchDocumentData(ref)),
    )
  } catch (error) {
    logger.error("Error", error)
  } finally {
    ctaUspsLoading.value = false
    ctaUspsLoaded.value = true
  }
}

watch(isMobileMenuOpen, () => {
  isMobileMenuOpen.value && !isDesktop && !ctaUspsLoaded.value && loadMobileHeaderItems()
})
</script>
