<template>
  <div v-if="items && items.length" class="form exponea-form" :class="isPreview ? 'has-edit-button' : ''">
    <br-manage-content-button :content="computedDocument" />
    <SfHeading v-if="title" class="exponea-form__heading" :title="title" :level="2" />
    <div v-html="htmlContent" />

    <Form v-slot="{ meta }" :validation-schema="validationSchema" @submit="submit">
      <div v-for="(item, index) in items" :key="index">
        <div
          v-if="item.type.selectionValues[0].key === 'textarea'"
          class="exponea-form__element"
          :name="item.fieldName"
        >
          <div class="exponea-form__birthday-label" v-html="formatLabel(item)"></div>
          <div style="clear: both"></div>
          <FormTextArea :name="item.fieldName" :cols="60" :rows="10" wrap="soft" :required="item.required" />
        </div>

        <div v-else-if="item.type.selectionValues[0].key === 'birthday'">
          <div class="exponea-form__birthday-label" v-html="formatLabel(item)"></div>
          <div style="clear: both"></div>

          <FormBirthday :name="item.fieldName + '#birthday'" />
        </div>
        <div
          v-else-if="item.type.selectionValues[0].key === 'checkbox'"
          class="exponea-form__element"
          :name="item.fieldName"
        >
          <FormCheckbox
            class="exponea-form__checkbox"
            :name="item.fieldName"
            :value="item.fieldName"
            :label="getLabelWithoutPTags(item)"
          >
            <template #label
              ><div
                class="sf-checkbox__label exponea-form__checkbox-label"
                v-html="
                  item.richTextlabel
                    ? parseBloomreachInternalLinks(item.richTextlabel.value) + (item.required ? '<p>&nbsp;*</p>' : '')
                    : item.required
                      ? '*'
                      : ''
                "
              ></div
            ></template>
          </FormCheckbox>
        </div>

        <div v-else class="exponea-form__element" :name="item.fieldName">
          <FormField
            :name="item.fieldName"
            :label="getLabelWithoutPTags(item)"
            :required="item.required"
            :type="item.type.selectionValues[0].key"
          />
        </div>
      </div>
      <SfButton v-if="!success" class="exponea-form__button" :disabled="!meta.valid">
        {{ submitButton }}
      </SfButton>

      <div v-html="privacyPolicy" />
      <div v-if="errors.length" class="errors errors bg-danger--variant text-danger">
        <p v-for="error in errors" :key="error">{{ $t(error) }}</p>
      </div>
      <div v-if="success" class="exponea-form__success">{{ submitText }}</div>
    </Form>
  </div>
</template>

<script setup>
import useExponeaApi from '~/composables/useExponeaApi'
import { parseBloomreachInternalLinks } from '~/utils/urlHelpers'

const props = defineProps({
  component: null,
  document: null,
  page: null,
})

let form = ref({})

const getItemContent = (ref) => {
  return props.page.getContent(ref)?.getData() || {}
}

onMounted(() => {
  const exponeaStore = useExponeaStore()
  const { firstname, lastname, email } = exponeaStore.user
  form.value = { firstname, lastname, email }
  // Needed to make sure that checkboxes are handled as booleans
  items.value?.forEach((item) => {
    if (item.type.selectionValues[0].key === 'checkbox') {
      form.value[item.fieldName] = false
    }
  })
})

const errors = ref([])
const success = ref(false)

const computedDocument = computed(() => {
  if (props.document) return props.document

  const { document } = props.component?.getModels()
  return props.page.getContent(document)
})
const isPreview = computed(() => props.page?.isPreview())
const data = computed(() => computedDocument.value?.getData() || {})
const title = computed(() => data.value.title)
const submitButton = computed(() => data.value.submitButton)
const htmlContent = computed(() => data.value.content?.value)
const privacyPolicy = computed(() => data.value.privacyPolicy?.value)
const submitText = computed(() => data.value.submitText)
const items = computed(() => data.value.formItems?.map((item) => getItemContent(item)))

const setValidationScheme = () => {
  let validations = {}
  items.value?.forEach((item) => {
    if (!item.required) return
    if (item.type.selectionValues[0].key === 'email') {
      return (validations[item.fieldName] = 'required|email')
    }
    if (item.type.selectionValues[0].key === 'phone') {
      return (validations[item.fieldName] = 'required|phone')
    }
    if (item.type.selectionValues[0].key === 'birthday') {
      validations[item.fieldName + '#birthday.day'] = 'required'
      validations[item.fieldName + '#birthday.month'] = 'required'
      validations[item.fieldName + '#birthday.year'] = 'required'
      return
    }
    validations[item.fieldName] = 'required'
  })
  return validations
}

const validationSchema = setValidationScheme()

const { post } = useExponeaApi()

const getLabelWithoutPTags = (item) => item?.richTextlabel?.value?.replace('<p>', '').replace('</p>', '').trim()

const formatLabel = (item) =>
  item.richTextlabel?.value ? parseBloomreachInternalLinks(item.richTextlabel.value) : item.label

const submit = async (values) => {
  let formValid = true

  const fields = {}
  Object.keys(values).forEach((key) => {
    if (key.includes('#birthday')) {
      const strippedKey = key.replace('#birthday', '')
      const day = values[key].day
      const month = values[key].month
      const year = values[key].year
      const birthdayTimestamp = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 12, 0, 0).getTime() / 1000 // Not 100% sure this is correct. But it's the same as it was...
      fields[strippedKey] = birthdayTimestamp
    } else {
      fields[key] = values[key]
    }
  })

  const theProperties = {
    action: 'new',
    signup_source: data.value.source,
    consent_list: [
      {
        action: 'accept',
        category: 'newsletter',
        valid_until: 'unlimited',
      },
    ],
  }
  const theMergedProperties = { ...theProperties, ...fields }
  const body = {
    customer_ids: { registered: values.email }, // assumes email is always present
    properties: theMergedProperties,
    event_type: 'double_opt_in',
  }

  try {
    if (formValid) {
      await post('/customers/events', body, 'track')
      pushEventToGa()
      success.value = true
      errors.value = []
    }
  } catch (error) {
    success.value = false
    errors.value = error
  }
}

const pushEventToGa = () => {
  dataLayer.push({
    event: 'GAEvent',
    eventCategory: 'Marketing Automation',
    eventAction: 'Contact Signup',
    eventLabel: data.value.source,
    eventValue: undefined,
  })
}
</script>

<style lang="scss">
.exponea-form {
  margin: 80px auto 120px;
  max-width: 600px;

  a {
    display: inline-block;
    text-underline-offset: 2px;
    text-decoration: underline;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      color: var(--cta-pink-color);
    }
  }

  &__element {
    display: block;
    margin-bottom: var(--spacer-base);
    width: 100%;
  }

  &__select {
    display: flex;
    align-items: center;
    --select-option-font-size: var(--font-size--lg);
    flex-wrap: wrap;
  }

  &__horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--spacer-lg);
  }

  &__checkbox-label,
  &__birthday-label {
    p {
      display: inline-block;
      margin: 0;
      text-align: left;
    }
  }

  &__success {
    margin-top: var(--spacer-base);
    border-radius: var(--spacer-2xs);
    padding: var(--spacer-sm);
    font-size: var(--font-size--base);
    background: #32b304;
    color: var(--c-white);
  }

  .sf-input__label {
    max-width: calc(100% - 32px);
    inline-size: inherit;
    word-wrap: break-word;
  }
}
</style>
