export const spacingMap = {
  Tiny: 'tiny',
  Small: 'sm',
  Medium: 'md',
  Large: 'lg',
  XLarge: 'xl',
  XXLarge: 'xxl',
  'Huge/Default': 'default',
};

/**
 * Returns correct spacing classname from Bloomreach naming (banner / rich-text components)
 */
export const getSpacingClassName = (size: string) => {
  return spacingMap[size] ?? false;
};

export default getSpacingClassName;
