<template>
  <div class="main-banner">
    <SfBanner
      v-if="firstBanner"
      :button-text="firstBanner.ctaTitle"
      :title="firstBanner.bannerTitle"
      :link="firstBanner.ctaUrl"
      class="main-banner-item"
    >
      <template #description>
        <BynderAsset
          :data="asset.data"
          :dataMobile="asset.dataMobile"
          :size="asset.size"
          :ratio="asset.ratio"
          :ratioMobile="asset.ratioMobile"
          loading="eager"
        />
        <br-manage-content-button :content="firstBanner.content" />
      </template>
      <template #call-to-action>
        <SfLink
          class="sf-button sf-button--white"
          :class="{ 'sf-button--invisible': !firstBanner.ctaTitle }"
          :aria-disabled="false"
          :link="firstBanner.ctaUrl || firstBanner.ctaLink"
        >
          {{ firstBanner.ctaTitle }}
        </SfLink>
      </template>
    </SfBanner>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  banners: {
    type: Array,
    required: true,
  },
})

const firstBanner = computed(() => props.banners[0] || false)
const asset = computed(() => {
  return {
    data: firstBanner.value.content.model.data.bannerImage,
    dataMobile: firstBanner.value.content.model.data.mobileBannerImage,
    size: '100vw',
    ratio: 0.2976,
    ratioMobile: 1.0256,
  }
})
</script>

<style lang="scss">
@import '@/assets/css/helpers';

.hst-container-item:has(.main-banner) {
  grid-column: 1 / -1;
}

.main-banner {
  --mj-component-margin-bottom: var(--spacer-xl);
  position: relative;
  margin-bottom: var(--mj-component-margin-bottom);

  @include for-desktop {
    --mj-component-margin-bottom: var(--spacer-3rem);
  }

  .has-edit-button & {
    max-width: 100%;
  }

  .two-column-layout__content & {
    width: 100%;
    transform: none;
  }

  .bynder-asset {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    @include for-desktop {
      left: auto;
      max-width: 1680px;
      object-fit: cover;
      object-position: center center;
    }
  }
}

.main-banner-item {
  position: relative;
  overflow: hidden;
  user-select: none;
  --banner-color: var(--white-color);
  --banner-align-items: center;
  --banner-wrapper-justify-content: flex-end;
  --banner-wrapper-width: 100%;
  --button-background: var(--white-color);
  --button-color: var(--c-text);
  --button-text-transform: none;
  --banner-height: 115vw;
  --banner-title-margin: 0 0 var(--spacer-sm);
  --banner-title-font-family: var(--font-family--tertiary);
  --banner-title-font-weight: bold;
  --banner-title-font-size: calc(16px + 3vw);

  @include for-desktop {
    --banner-height: 500px;
    --banner-title-font-size: 3rem;
    --banner-title-margin: 0 0 var(--spacer-md);
  }

  .sf-banner__wrapper {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}
</style>
