<template>
  <div v-if="component" :class="isPreview ? 'has-edit-button' : ''">
    <br-manage-content-button :content="content" />
    <div class="category-page-header">
      <BynderAsset
        class="category-page-header__asset"
        :data="asset.data"
        :dataMobile="asset.dataMobile"
        :size="asset.size"
        :ratio="asset.ratio"
        :ratioMobile="asset.ratioMobile"
        loading="eager"
      />

      <div class="category-page-header__container container">
        <div class="category-page-header__content">
          <header class="product-list__header">
            <h1 class="category-page-header__title" v-html="headerTitle" />
            <span class="product-list__header-item-count desktop-only">
              {{ totalProducts }} {{ $t('product', totalProducts) }}
            </span>
          </header>
          <HTMLContent tag="div" :content="headerText" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { parseBloomreachInternalLinks } from '~/utils/urlHelpers'

const props = defineProps({
  component: null,
  page: null,
})

const bloomreachDiscoveryStore = useBloomreachDiscoveryStore()
const { pagination } = storeToRefs(bloomreachDiscoveryStore)

const totalProducts = computed(() => pagination.value?.totalProducts || 0)
const isPreview = computed(() => props.page?.isPreview())
const pageDocument = computed(() => props.page?.getDocument())

onMounted(() => {
  nextTick(() => {
    document.querySelector('#category')?.classList.add('category-has-header')
  })
})

const content = computed(() => {
  const { document } = props.component?.getModels()
  return props.page.getContent(document)
})

const model = computed(() => {
  const data = content.value?.getData()

  return (
    (data && {
      ...data,
      image: data.image,
    }) ||
    {}
  )
})

const headerText = computed(() => parseBloomreachInternalLinks(model.value.richTextDescription?.value || ''))

const headerTitle = computed(
  () => pageDocument.value?.getData()?.pageTitle || pageDocument.value?.getData().displayName,
)

const asset = computed(() => ({
  data: model.value.image,
  dataMobile: model.value.mobileBannerImage,
  size: '100vw',
  ratio: 0.3472,
  ratioMobile: 0.48,
}))
</script>

<style lang="scss">
.hst-container-item:has(.category-page-header) {
  grid-column: 1 / -1;
}

.category-page-header {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  text-align: left;
  margin-bottom: -16px; // Compensation for padding on .product-list

  a {
    display: inline-block;
    text-underline-offset: 2px;
    text-decoration: underline;
    transition: all 0.2s ease;

    appearance: none;
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--cta-pink-color);
    }
  }

  @include for-desktop {
    .category-page-header__asset,
    .category-page-header__video,
    .category-page-header__content {
      flex: 0 0 calc(50% - calc(var(--spacer-md) / 2));
    }
  }

  .product-list__header {
    display: flex;
    align-items: flex-end;

    &-item-count {
      font-size: var(--font-size-13);
      line-height: var(--spacer-lg);
      color: var(--gray-secondary-color);
    }
  }

  &__asset {
    position: relative;
    overflow: hidden;
    z-index: -1;
    margin: 0;
    height: 48vw;
    width: 100%;
    pointer-events: none;

    @include for-desktop {
      height: 500px;
    }
  }

  &__container {
    @include for-desktop {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &__content {
    width: 100%;
    position: relative;
    flex: 0 0 50%;
    padding: var(--spacer-md) var(--spacer-lg);
    word-break: break-word;
    font-family: var(--font-family--primary);
    font-weight: var(--font-weight-default);
    line-height: var(--sm-line-height);
    text-align: left;
    padding: var(--spacer-sm);

    @include for-desktop {
      position: absolute;
      height: fit-content;
      font-size: var(--font-size--base);
      line-height: 1.375;
      max-width: 664px;
      bottom: 0;
      background-color: var(--white-color);
      margin: 0;
      left: 0;
      padding: var(--spacer-sm);
      border-radius: 4px 4px 0px 0px;
    }
  }

  &__title {
    margin: 0 var(--spacer-xs) 0 0;
    @include for-mobile {
      padding-bottom: var(--spacer-xs);
    }
  }
}
</style>
