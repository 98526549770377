<template>
  <div class="sf-scrollable" :class="{ 'is-open': !isHidden }">
    <div ref="content" class="sf-scrollable__content" :style="style">
      <slot />
    </div>
    <slot name="view-all" v-bind="{ hasScroll, showText, hideText }">
      <SfButton v-show="hasScroll" class="sf-button--text sf-scrollable__view-all" @click="isHidden = !isHidden">
        <span v-if="isHidden">{{ showText }}</span>
        <span v-else>{{ hideText }}</span>
      </SfButton>
    </slot>
  </div>
</template>

<script setup>
const props = defineProps({
  maxContentHeight: {
    type: String,
    default: '',
  },
  showText: {
    type: String,
    default: 'Show',
  },
  hideText: {
    type: String,
    default: 'Hide',
  },
})

const isHidden = ref(true)
const hasScroll = ref(false)
const contentEl = ref(undefined)
const content = ref()
const style = computed(() => ({
  '--_scrollable-max-height': props.maxContentHeight.trim() ? props.maxContentHeight : undefined,
}))

const sizeCalc = () => {
  const containerHeight = content.value.offsetHeight
  const contentHeight = contentEl.value.offsetHeight
  hasScroll.value = contentHeight > containerHeight
}

onMounted(() => {
  nextTick(() => {
    contentEl.value = content.value.$el.querySelector('.simplebar-content')
    if (typeof MutationObserver === 'undefined' || !contentEl.value) return
    const observer = new MutationObserver(sizeCalc)
    sizeCalc()
    observer.observe(contentEl.value, { childList: true })
  })
})
</script>

<style lang="scss">
@import './styles/molecules/SfScrollable.scss';
</style>
